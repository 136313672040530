import React, { Component } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { Link } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Loader from "components/Loader";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import { MultiSelect } from "react-multi-select-component";
import { link } from "react-router-dom";

class LiveQuiz extends Component {
  state = {
    visible: false,
    notifyDelete: false,
    delLoading: false,
    rowId: 0,
    notifyStatus: false,
    empty: false,
    secondaryCategories: [],
    status: "",
    active: 1,
    act: 0,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    delNot: false,
    lastPageView: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    notificationModalVisible: false,
    durations: [
      { label: "Quick", value: "0" },
      { label: "1 Min", value: "1" },
      { label: "5 Min", value: "5" },
      { label: "15 Min", value: "15" },
      { label: "30 Min", value: "30" },
      { label: "60 Min", value: "60" },
      { label: "120 Min", value: "120" },
    ],
    selectedQuiz: {},
    selectedDuration: [],
    testEnd: 0,
    questionTime: 30,
    countdowns: [],
  };

  componentDidMount() {
    this.getLiveQuiz();
  }
  getLiveQuiz = () => {
    axios
      .get(
        Constants.getUrls.get_test_series + "?per_page=" + this.state.per_page
      )
      .then((resp) => {
        const questionQuantities = resp.data.data.map(
          (item) => item.no_of_questions
        );
  
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.last_page; i++) {
          btnIdArray.push(i);
        }
  
        const countdowns = resp.data.data.map((sec) => {
          const examDateTime = new Date(`${sec.exam_date} ${sec.test_timing}:00`);
          const totalTimeWithBuffer =
            sec.no_of_questions * this.state.questionTime * 1000 + 180 * 1000;
          const remainingTime = examDateTime.getTime() + totalTimeWithBuffer - new Date().getTime();
          return Math.max(remainingTime, 0); // Ensure no negative values
        });
  
        this.setState(
          {
            btnIdArray,
            secondaryCategories: resp.data.data,
            questionQuantities,
            total: resp.data.total,
            from: resp.data.from,
            to: resp.data.to,
            countdowns,
          },
          () => {
            // Set up countdown timer updates
            this.startCountdown();
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching live quiz data:", error);
      });
  };
  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      this.setState((prevState) => ({
        countdowns: prevState.countdowns.map((time) =>
          time > 1000 ? time - 1000 : 0
        ), // Decrement by 1 second
      }));
    }, 1000);
  };
  
  
  componentWillUnmount() {
    clearInterval(this.countdownInterval);
  }
    

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios
      .delete("https://admin.akclasses.in/api/test_series/" + id)
      .then((resp) => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          this.setState({
            notifyDelete: false,
            visible: false,
          });
          this.getLiveQuiz();

          // window.location.reload();
        }, 1000);
      });
  };
  handleModal = (sec) => {
    this.setState({
      rowId: sec.id,
      visible: true,
    });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
            "https://admin.akclasses.in/api/test_series?per_page=" +
              this.state.per_page
          )
          .then((resp) => {
            //console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                secondaryCategories: resp.data.data,
                total: resp.data.total,
                from: resp.data.from,
                to: resp.data.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v) => {
    let commonUrl =
      "https://admin.akclasses.in/api/test_series?per_page=" +
      this.state.per_page +
      "&page=";
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        secondaryCategories: resp.data.data,
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleSelect = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(
            "https://admin.akclasses.in/api/courseCategory/status/" + id,
            payload
          )
          .then(() => {
            //console.log(res);
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState(
                {
                  notifyStatus: false,
                  fromInc: 1,
                  active: 1,
                  toInc: 5,
                },
                () => {
                  this.getLiveQuiz();
                }
              );
            }, 1000);
          });
      }
    );
  };

  manageNotification = async () => {
    const payload = {
      test_series_id: this.state.selectedQuiz.id,
      duration: this.state.selectedDuration.map((dura) => dura.value),
    };
    const { data } = await axios.post(
      Constants.postUrls.manageNotiForQuiz,
      payload
    );
    if (data.status === "success") {
      this.setState({
        showNoti: true,
        notificationModalVisible: false,
        selectedDuration: [],
      });
      setTimeout(() => {
        this.setState({ showNoti: false });
      }, 3000);
    }
    console.log(data);
  };

  render() {
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.delNot ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Live Quiz Deleted!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          {this.state.showNoti ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Live Quiz Updated successfully!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <h3>Live Quiz</h3>
                <Link to="/admin/addlivequiz">
                  <Button className="" color="warning">
                    Add Live Quiz
                  </Button>
                </Link>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Name",
                    "Registration Type",
                    "Registration Fee",
                    "Outer Image",
                    "Inner Image",
                    "Registration Start Date & Time",
                    "Registration End Date & Time",
                    "Exam Date",
                    "Test Timing",
                    "Quiz Notification",
                    "Update",
                    "Question",
                    "Delete",
                    "Result",
                    "Total Timing",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => {
                      const examDateTime = new Date(
                        `${sec.exam_date} ${sec.test_timing}:00`
                      );

                      const totalTimeWithBuffer =
                        this.state.questionQuantities[index] *
                          this.state.questionTime +
                        180 * 1000; // 180 seconds = 3 minutes

                      const currentDateTime = new Date().getTime();

                      const expectedCompletionTime =
                        examDateTime.getTime() + totalTimeWithBuffer;

                      const isExamCompleted =
                        currentDateTime > expectedCompletionTime;

                      const isPreviewTime =
                        currentDateTime >
                          expectedCompletionTime - 3 * 60 * 1000 &&
                        currentDateTime <= expectedCompletionTime;

                      let buttonText = "Test is not started yet";
                      if (isExamCompleted) {
                        buttonText = "Leadership Board";
                      } else if (isPreviewTime) {
                        buttonText = "In Preview";
                      }
                      const isButtonDisabled =
                        buttonText !== "Leadership Board";
                        const countdownTime = this.state.countdowns[index] || 0;
                        const minutes = Math.floor(countdownTime / 60000);
                        const seconds = Math.floor((countdownTime % 60000) / 1000);
                      return [
                        [index + 1],
                        [sec.name],
                        [sec.reg_type],
                        [sec.reg_fee],
                        [
                          sec.app_flash_image && (
                            <img
                              src={
                                "https://admin.akclasses.in/" +
                                sec.app_flash_image
                              }
                              alt=""
                              style={{ width: "150px", height: "100px" }}
                            />
                          ),
                        ],
                        [
                          sec.image && (
                            <img
                              src={"https://admin.akclasses.in/" + sec?.image}
                              alt=""
                              style={{ width: "150px", height: "100px" }}
                            />
                          ),
                        ],
                        [
                          <div key={index}>
                            <span>{sec.reg_start_date}</span>
                            <br />
                            <span>{sec.reg_start_time + ":00"}</span>
                          </div>,
                        ],
                        [
                          <div key={index}>
                            <span>{sec.reg_end_date}</span>
                            <br />
                            <span>{sec.reg_end_time + ":00"}</span>
                          </div>,
                        ],
                        [sec.exam_date],
                        [<span key={index}>{sec.test_timing + ":00"}</span>],
                        [
                          <Button
                            className="btn-round"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                selectedQuiz: sec,
                                notificationModalVisible: true,
                              });
                            }}
                          >
                            Manage
                          </Button>,
                        ],
                        [
                          <Link
                            key={index}
                            to={{
                              pathname: "/admin/updatelivequiz/" + sec.id,
                              state: { categoryData: sec },
                            }}
                          >
                            <Button className="btn-round" color="info">
                              Update
                            </Button>
                          </Link>,
                        ],
                        [
                          <Link
                            key={index}
                            to={{
                              pathname: "/admin/livequizquestion/" + sec.id,
                              state: { categoryData: sec },
                            }}
                          >
                            <Button className="btn-round" color="primary">
                              Question
                            </Button>
                          </Link>,
                        ],
                        [
                          <Button
                            key={index}
                            onClick={() => {
                              this.handleModal(sec);
                            }}
                            className="btn-round"
                            color="danger"
                          >
                            {this.state.act === parseInt(sec.id) ? (
                              <Loader />
                            ) : (
                              "Delete"
                            )}
                          </Button>,
                        ],
                        [
                          isButtonDisabled ? (
                            <Button
                              key={index}
                              className="btn-round"
                              color="rose"
                              disabled
                            >
                              {buttonText}
                            </Button>
                          ) : (
                            <Link
                              key={index}
                              to={{
                                pathname: "/admin/leadership/" + sec.id,
                                state: { categoryData: sec.id },
                              }}
                            >
                              <Button className="btn-round" color="success">
                                {buttonText}
                              </Button>
                            </Link>
                          ),
                        ],
                        
                        [
                          buttonText === "Leadership Board" ? (
                            <p>{this.state.questionQuantities[index] * this.state.questionTime}</p>
                          ) : (
                            <p>{`${minutes}:${seconds.toString().padStart(2, "0")}`}</p>
                          ),
                        ],
                      ];
                    }
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <Modal
            visible={this.state.visible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() => this.setState({ visible: false })}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Live Quiz Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>

          <Modal
            visible={this.state.notificationModalVisible}
            width="600"
            height="100"
            effect="fadeInUp"
            onClickAway={() =>
              this.setState({
                notificationModalVisible: false,
                selectedDuration: [],
              })
            }
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <div>
                      <h3>{this.state.selectedQuiz?.name}</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <MultiSelect
                            style={{ width: "50%", marginBottom: 100 }}
                            options={this.state.durations}
                            onChange={(data) => {
                              this.setState({ selectedDuration: data });
                            }}
                            value={this.state.selectedDuration}
                            labelledBy={"Durations"}
                          />
                          <br />
                          <GridContainer>
                            <GridItem xs={6} style={{ textAlign: "right" }}>
                              <Button
                                className=""
                                color="default"
                                onClick={() => {
                                  this.setState({
                                    notificationModalVisible: false,
                                    selectedDuration: [],
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </GridItem>
                            <GridItem xs={6}>
                              <Button
                                className=""
                                color="primary"
                                onClick={() => {
                                  this.manageNotification();
                                }}
                              >
                                Save
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Modal>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LiveQuiz;
