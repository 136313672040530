import React, { Component } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Language from "@material-ui/icons/Language";
import CourseLogo from "../../components/youtube/online-training.png";
import BookLogo from "../../components/youtube/open-book.png";
import PremiumUser from "../../components/youtube/premium-account.png";
import FreeUser from "../../components/youtube/user.png";
import CountUp from "react-countup";
import Ftof from "components/youtube/Ftof";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Loader from "components/Loader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { MultiSelect } from "react-multi-select-component";
// import editorConfiguration from "../../variables/EditorConfiguration";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import Modal from "react-awesome-modal";
import Constants from "variables/Constants";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Graph from "components/youtube/Graph";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100"];
class Dashboard extends Component {
  state = {
    count: 0,
    notifyStatus: false,
    searching: false,
    download: false,
    notifyDelete: false,
    secondaryCategories: [],
    per_page: "10",
    status: "",
    active: 1,
    currentPage: 1,
    date: false,
    empty: true,
    user: "",
    products: [],
    apiColumns: [
      "USER",
      "ORDER_TYPE",
      "ORDER_DATE",
      "EMAIL",
      "MOBILE_NO",
      "PRODUCT",
      "PRODUCT_KEY",
      "PAYMENT_STATUS",
      "DELIVERY_STATUS",
      "VARIANT",
      "ATTEMPT",
      "TYPE",
      "BATCH",
      "LANGUAGE",
    ],
    columns: [],
    columnss: [],
    column: [],
    attemptss: [],
    productss: [],
    addedData: [],
    product: [],
    singleProduct: "",
    types: [
      { label: "Courses", value: "Course" },
      { label: "Test Series", value: "Test_Series" },
      { label: "Books", value: "Books" },
      { label: "Extension", value: "Extend_Validity" },
    ],
    ordTypes: [
      { label: "Online", value: "Online" },
      { label: "Offline", value: "Offline" },
    ],
    orderTypes: ["Online", "Offline"],
    frmDate: "",
    toDate: "",
    type1: "",
    type2: "Send Written Message",
    type: [],
    courses: [],
    course: [],
    testSeries: [],
    test: [],
    books: [],
    book: [],
    extensions: [],
    attempts: [],
    attempt: [],
    extension: [],
    ordType: [],
    mobile: 0,
    ord: true,
    loading: false,
    prevPage: 0,
    message: "",
    stringToAttach: "",
    searchOrderId: "",
    typess: [],
    nextPage: 0,
    lastPage: 0,
    notifyNote: false,
    lastPageView: false,
    firstPageView: false,
    mess: false,
    comment: false,
    logDetail: false,
    showStrategy: false,
    import: false,
    addShip: false,
    mail: false,
    print: false,
    delModal: false,
    proDetail: false,
    visible: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    logistics: [],
    strategies: [],
    remainingStrategies: [],
    filteredStrategies: [],
    stringsArray: [],
    filteredBtnIdArray: [],
    ordtype: "",
    addedStrategies: [],
    notaddedStrategies: [],
    notAddedStrats: [],
    ftofbatchId: "",
    product: "",
    ftofbatches: [],
    selectedBatchId: "",
    faceToFaceBatchList: [],
    fbatchId: "",
    fmodal: false,
    fbatchName: "",
    selectedBatchId: "",
    id: "",
  };
  componentDidMount = () => {
    this.searchOrderId();

    axios
      .get("https://admin.akclasses.in/api/ftof_batches_days/get_ftof_batches")
      .then((res) => {
        console.log(res.data.data, "👍");
        this.setState({
          faceToFaceBatchList: res.data.data,
          // batchId: res.data.data.course.ftofbatch,
        });
      })
      .catch((error) => {
        console.error("Error fetching face to face batch data:👎", error);
      });

    axios
      .get(
        "https://admin.akclasses.in/api/productCount?premimum_user=premium&free_user=Free&courses=COURSES&Book=BOOKS"
      )
      .then((resp) => {
        this.setState({ count: resp.data });
      })
      .catch((error) => {
        console.error(
          "Error fetching product count:",
          error.message || error.response
        );
      });

    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Courses")
      .then((resp) => {
        this.setState({ courses: resp.data });
      });
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Test_Series")
      .then((resp) => {
        this.setState({ testSeries: resp.data });
      });
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Books")
      .then((resp) => {
        this.setState({ books: resp.data });
      });
    axios
      .get("https://admin.akclasses.in/api/coursesR?type=Extend_Validity")
      .then((resp) => {
        this.setState({ extensions: resp.data });
      });
    axios.get("https://admin.akclasses.in/api/logistic").then((resp) => {
      // console.log(resp.data);
      this.setState({
        faculties: resp.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/course_with_variant")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          productsss: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/get_type").then((resp) => {
      //   console.log(resp.data);
      this.setState({
        typesss: resp.data,
      });
    });
    axios.get("https://admin.akclasses.in/api/logistic").then((resp) => {
      //console.log(resp.data.data);
      this.setState({
        logistics: resp.data.data,
      });
    });
    axios
      .get("https://admin.akclasses.in/api/couponCodes?type=REFERENCE_CODE")
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          referenceCodes: resp.data,
        });
      });
    axios.get("https://admin.akclasses.in/api/attempt").then((resp) => {
      // console.log(resp.data);
      resp.data.forEach((v) => {
        this.state.attempts.push({
          label: v.title,
          value: v.id,
        });
      });
      this.state.apiColumns.forEach((v) => {
        this.state.columns.push({
          label: v,
          value: v,
        });
      });
      this.setState({
        attempts: this.state.attempts,
        columns: this.state.columns,
      });
    });
  };

  handleFetchFbatch = (fbatchId) => {
    axios
      .get(`https://admin.akclasses.in/api/getSingleFbatch/${fbatchId}`)
      .then((res) => {
        const batchData = res?.data?.data;
        if (batchData && typeof batchData === "object") {
          console.log(batchData, "👍");
          this.setState({
            fbatchName: batchData.batch_name || "No batch found",
          });
        } else {
          console.error("Batch data is undefined or invalid");
          this.setState({
            fbatchName: "No batch found",
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error, "👎");
        this.setState({
          fbatchName: "Error fetching batch data",
        });
      });
  };

  handleBatchChange = (e) => {
    this.setState({ selectedBatchId: e.target.value });
  };

  handleFaceToFace = () => {
    const { selectedBatchId, id } = this.state;
    console.log(this.state.id, "👿");

    if (!selectedBatchId) {
      console.error("Batch ID is not selected!");
      return;
    }

    const payload = { ftofbatch: selectedBatchId };

    axios
      .post(`${Constants.postUrls.updateFtoFBatchonline}${id}`, payload)
      .then((response) => {
        console.log("Batch successfully allotted:", response.data);
        this.fetchOrderReport(); // Fetch updated data
        this.setState({ fmodal: false }); // Close modal
      })
      .catch((error) => {
        console.error("Error updating face-to-face batch:", error);
      });
  };

  comparer = (otherArray) => {
    return function(current) {
      return (
        otherArray.filter(function(other) {
          return other.id == current.id;
        }).length == 0
      );
    };
  };
  importUser = () => {
    this.setState({ loading: true });
    let payLoad = {
      type: this.state.type1,
      name: this.state.student,
      email: this.state.email,
      mobile: this.state.mobile,
      key: this.state.pKey,
      // password: this.state.password,
      date: this.state.orderDate,
    };
    axios.post("https://admin.akclasses.in/api/user_sms", payLoad).then(() => {
      // console.log(resp);
      this.setState({
        notifyNote: true,
        loading: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };
  sendEmail = () => {
    console.log(this.state.ordType);
    console.log(this.state.ordtype);
    this.setState({ loading: true });
    let strategies = [];
    this.state.notAddedStrats.forEach((v) => {
      strategies.push(v.value);
    });
    console.log(strategies);
    let payLoad = {
      order_id: this.state.rowId,
      strIds: strategies,
    };
    if (this.state.ordtype === "Offline") {
      payLoad.offline = "";
    } else {
      payLoad.online = "";
    }
    axios
      .post("https://admin.akclasses.in/api/addUser_strategy", payLoad)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "failed") {
          this.setState({
            loading: false,
            mailFail: true,
            strMessage: resp.data.message,
          });
          setTimeout(() => {
            this.setState({
              mailFail: false,
              strMessage: "",
            });
          }, 4000);
        } else {
          this.setState({
            loading: false,
            mailS: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };
  findReferenceCode = (id) => {
    let obj = this.state.referenceCodes.find((v) => v.REF_ORDER_NO === id);
    return obj === undefined ? "NA" : obj.CODE;
  };
  findFaculty = (data) => {
    let obj = this.state.faculties.find((v) => v.id === data[0].faculty_id);
    if (obj !== undefined) {
      return obj.name;
    }
  };
  findCourse = (id) => {
    let obj = this.state.productsss.find((v) => v.course.ID === id);
    // console.log(obj);
    if (obj !== undefined) {
      return obj.course.faculty.length > 1
        ? "AK Team"
        : this.findFaculty(obj.course.faculty);
    }
  };
  applyKey = () => {
    axios
      .post(
        "https://admin.akclasses.in/api/extend_validity?product_key=" +
          this.state.pkey +
          "&product_id=" +
          this.state.pro
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.status === "success") {
          this.setState({
            redirect: true,
            loading: false,
            user: resp.data.user,
            pKey: resp.data.extended_product_key,
            productId: resp.data.product.extend_course_id,
            title: resp.data.product.extend_course.TITLE,
            grandTotal: resp.data.product.extend_course_price,
          });
        } else {
          this.setState({ warn: true, message: resp.data.message });
          setTimeout(() => {
            this.setState({ warn: false });
          }, 2000);
        }
      });
  };
  sendMessage = () => {
    this.setState({ loading: true });
    let payLoad = {
      message:
        this.state.type2 === "Send Written Message"
          ? this.state.message
          : this.state.type2 === "Send Login Credentials"
          ? "Hey," +
            this.state.student +
            " Your Email Id is " +
            this.state.email +
            " and Password is " +
            this.state.password
          : this.state.type2 === "Send Product Key"
          ? "Hey," +
            this.state.student +
            "\nYour Product Key is " +
            this.state.pKey
          : "Hey," +
            this.state.student +
            "\nCourier Company is " +
            this.state.courierCom +
            ",Tracking Id is " +
            this.state.trackingNo +
            " and Trackin Link is " +
            this.state.tracLink,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/user_off_ord_sms/" + this.state.rowId,
        payLoad
      )
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  // handleDelete = id => {
  //   this.setState({ delLoading: true });
  //   axios
  //     .post("https://admin.akclasses.in/api/offlineOrder/delete/" + id)
  //     .then(() => {
  //       this.setState({
  //         notifyDelete: true,
  //         delLoading: false
  //       });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     });
  // };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    if (this.state.ordtype === "Offline") {
      axios.post(Constants.postUrls.deleteOfflineOrder + id).then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    } else {
      axios.post(Constants.postUrls.deleteOnlineOrder + id).then(() => {
        this.setState({
          notifyDelete: true,
          delLoading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    axios
      .post(
        "https://admin.akclasses.in/api/offlineOrder/notes/" + this.state.rowId,
        {
          note: this.state.notes,
        }
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ notifyNote: false });
          window.location.reload();
        }, 2000);
      });
  };

  addShipping = () => {
    this.setState({ loading: true });
    let payLoad = {
      tracking_no: this.state.trackingNo,
      prod_key: this.state.pKey,
      logistic: this.state.logic,
    };
    axios
      .post(
        "https://admin.akclasses.in/api/shipping_detail/" + this.state.orderId,
        payLoad
      )
      .then(() => {
        //console.log(resp);
        this.setState({
          notification: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  sendMail = () => {
    this.setState({ loading: true });
    let payLoad = {
      messages: this.state.emailMess,
      name: this.state.student,
      email: this.state.email,
    };
    axios
      .post("https://admin.akclasses.in/api/email_user", payLoad)
      .then(() => {
        // console.log(resp);
        this.setState({
          notifyNote: true,
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  searchOrderId = () => {
    console.log(this.state.ordType);
    console.log(this.state.ordtype);
    this.setState({ searching: true });
    let fd = new FormData();
    let stringsArray = [];

    // Helper function to format the date
    const formatDate = (date) => date.toISOString().split("T")[0];

    // If `searchOrderId` is empty, include the date range in the payload
    if (!this.state.searchOrderId) {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 20);

      // If no date is selected, use the default date range
      if (this.state.frmDate === "" && this.state.toDate === "") {
        const defaultFromDate = formatDate(sevenDaysAgo);
        const defaultToDate = formatDate(today);

        this.setState({
          frmDate: defaultFromDate,
          toDate: defaultToDate,
        });

        fd.append("frmdate", defaultFromDate);
        fd.append("todate", defaultToDate);
        stringsArray.push("frmdate", "todate");
      } else if (this.state.frmDate !== "" && this.state.toDate !== "") {
        fd.append("frmdate", this.state.frmDate);
        fd.append("todate", this.state.toDate);
        stringsArray.push("frmdate", "todate");
      }
    }

    // Add order type
    if (this.state.ordType.length === 1) {
      const orderType = [this.state.ordType[0].value];
      fd.append("order_type", JSON.stringify(orderType));
    } else {
      fd.append("order_type", JSON.stringify(this.state.orderTypes));
    }

    // Add other fields (product, attempt, column, etc.)
    if (this.state.product.length > 0) {
      stringsArray.push("product");
      let products = this.state.product.map((v) => v.value);
      fd.append("product", JSON.stringify(products));
    }

    if (this.state.attempt.length > 0) {
      stringsArray.push("attempt");
      let attempts = this.state.attempt.map((v) => v.value);
      fd.append("attempt", JSON.stringify(attempts));
    }

    if (this.state.column.length > 0) {
      stringsArray.push("select");
      let columns = this.state.column.map((v) => v.value);
      fd.append("select", JSON.stringify(columns));
    }

    // Add user search if provided
    if (this.state.searchOrderId !== "") {
      stringsArray.push("user");
      fd.append("user", this.state.searchOrderId);
    }

    let str = stringsArray
      .map((v) => {
        return (
          v +
          "=" +
          (v === "user"
            ? this.state.searchOrderId
            : v === "product"
            ? JSON.stringify(this.state.productss)
            : v === "order_type"
            ? this.state.ordType[0].value
            : v === "type"
            ? JSON.stringify(this.state.typess)
            : v === "frmdate"
            ? this.state.frmDate
            : v === "todate"
            ? this.state.toDate
            : v === "attempt"
            ? JSON.stringify(this.state.attemptss)
            : v === "select"
            ? JSON.stringify(this.state.columnss)
            : "")
        );
      })
      .join("&");

    console.log(str);
    this.setState({ stringToAttach: str });

    // Append pagination info
    fd.append("per_page", this.state.per_page);

    // Send the request
    axios.post(Constants.postUrls.all_order_report, fd).then((resp) => {
      console.log(resp.data);
      let btnIdArray = Array.from(
        { length: resp.data.data.last_page },
        (_, i) => i + 1
      );
      const sortedData = resp.data.data.data.sort((a, b) => {
        const dateA = new Date(a.ORDER_DATE); // Replace 'date' with the correct key for your date field
        const dateB = new Date(b.ORDER_DATE);
        return dateB - dateA; // Ascending order; use `dateB - dateA` for descending order
      });

      this.setState(
        {
          date: false,
          searching: false,
          btnIdArray,
          fromInc: 1,
          active: 1,
          toInc: 5,
          ord: false,
          secondaryCategories: sortedData,
          total: resp.data.data.total,
          from: resp.data.data.from,
          to: resp.data.data.to,
          download: true,
        },
        () => {
          let filteredBtnIdArray = this.state.btnIdArray.filter(
            (v) => v >= this.state.fromInc && v <= this.state.toInc
          );
          this.setState({
            filteredBtnIdArray,
          });
        }
      );
    });
  };

  currentPage = (v) => {
    console.log(this.state.ordType);
    console.log(this.state.ordtype);
    this.setState({ searching: true });
    let fd = new FormData();
    let stringsArray = [];

    // Ensure frmDate and toDate are included
    if (this.state.frmDate !== "" && this.state.toDate !== "") {
      stringsArray.push("frmdate");
      stringsArray.push("todate");
      fd.append("frmdate", this.state.frmDate);
      fd.append("todate", this.state.toDate);
    }

    // Include type in the payload
    if (this.state.type.length > 0) {
      stringsArray.push("type");
      let types = this.state.type.map((v) => v.value);
      console.log(types);
      fd.append("type", JSON.stringify(types));
    }

    // Ensure order_type is always included
    if (this.state.ordType.length > 0) {
      stringsArray.push("order_type");
      const orderTypes =
        this.state.ordType.length === 1
          ? [this.state.ordType[0].value]
          : this.state.ordType.map((type) => type.value);
      fd.append("order_type", JSON.stringify(orderTypes));
    } else {
      // Include default order types when no selection is made
      stringsArray.push("order_type");
      fd.append("order_type", JSON.stringify(this.state.orderTypes));
    }

    // Include product in the payload
    if (this.state.product.length > 0) {
      stringsArray.push("product");
      let products = this.state.product.map((v) => v.value);
      console.log(products);
      fd.append("product", JSON.stringify(products));
    }

    // Include attempt in the payload
    if (this.state.attempt.length > 0) {
      stringsArray.push("attempt");
      let attempts = this.state.attempt.map((v) => v.value);
      fd.append("attempt", JSON.stringify(attempts));
    }

    // Include columns in the payload
    if (this.state.column.length > 0) {
      stringsArray.push("select");
      let columns = this.state.column.map((v) => v.value);
      console.log(columns);
      fd.append("select", JSON.stringify(columns));
    }

    // Include user if searchOrderId is present
    if (this.state.searchOrderId !== "") {
      stringsArray.push("user");
      fd.append("user", this.state.searchOrderId);
    }

    // Construct the query string with all parameters
    let queryString = stringsArray
      .map((v) => {
        return (
          v +
          "=" +
          (v === "user"
            ? this.state.searchOrderId
            : v === "product"
            ? JSON.stringify(this.state.productss)
            : v === "order_type"
            ? JSON.stringify(
                this.state.ordType.length > 0
                  ? this.state.ordType.map((type) => type.value)
                  : this.state.orderTypes
              )
            : v === "type"
            ? JSON.stringify(this.state.typess)
            : v === "frmdate"
            ? this.state.frmDate
            : v === "todate"
            ? this.state.toDate
            : v === "attempt"
            ? JSON.stringify(this.state.attemptss)
            : v === "select"
            ? JSON.stringify(this.state.columnss)
            : "")
        );
      })
      .join("&");

    console.log(queryString);
    this.setState({ stringToAttach: queryString });

    fd.append("per_page", this.state.per_page);

    // Include page number in the query string
    let commonUrl =
      Constants.postUrls.all_order_report + "?" + queryString + "&page=" + v;

    axios
      .post(commonUrl, fd)
      .then((resp) => {
        this.setState({
          total: resp.data.data.total,
          from: resp.data.data.from,
          to: resp.data.data.to,
          secondaryCategories: resp.data.data.data,
          active: v,
          searching: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ searching: false });
      });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }
  closfmodal() {
    this.setState({
      fmodal: false,
      fbatchId: "",
      fbatchName: "",
    });
  }

  closeModal() {
    this.setState({
      // fmodal: false,
      // fbatchId: "",
      // fbatchName: "",
      visible: false,
      logDetail: false,
      mail: false,
      proDetail: false,
      import: false,
      showStrategy: false,
      delModal: false,
      print: false,
      addShip: false,
      mess: false,
      comment: false,
      notaddedStrategies: [],
    });
  }
  handleModal = (sec, arg) => {
    this.setState(
      {
        singleProduct: sec.course ? sec.course.TITLE : "",
        mess: arg === "mess" ? true : false,
        comment: arg === "comment" ? true : false,
        logDetail: arg === "logDetail" ? true : false,
        showStrategy: arg === "showStrategy" ? true : false,
        import: arg === "import" ? true : false,
        addShip: arg === "addShip" ? true : false,
        mail: arg === "mail" ? true : false,
        print: arg === "print" ? true : false,
        delModal: arg === "delModal" ? true : false,
        proDetail: arg === "proDetail" ? true : false,
        orderId: sec.ORDER_ID,
        rowId: sec.ID,
        ordtype: sec.TYPE,
        osType: sec.variant ? sec.variant.os_type.os_type : "",
        mediaType: sec.variant ? sec.variant.media_type.media_type : "",
        viewValidity: sec.variant
          ? sec.variant.view_and_validity.view_and_validity
          : "",
        delStatus: sec.DELIVERY_STATUS ? sec.DELIVERY_STATUS : "",
        address: sec.ADDRESS ? sec.ADDRESS : sec.user ? sec.user.ADDRESS_1 : "",
        // address: sec.ADDRESS ? sec.ADDRESS : "",
        city: sec.CITY ? sec.CITY : sec.user ? sec.user.CITY : "",
        pincode: sec.PINCODE ? sec.PINCODE : sec.user ? sec.user.PINCODE : "",
        pKey: sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "",
        price: sec.GRAND_TOTAL ? sec.GRAND_TOTAL : "",
        student: sec.NAME
          ? sec.NAME
          : sec.user
          ? (sec.user.FIRST_NAME ? sec.user.FIRST_NAME : "") +
            " " +
            (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
          : "",
        password: sec.user ? sec.user.DE_PASSWORD : null,
        email: sec.user ? sec.user.email : sec.EMAIL,
        mobile: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        trackingNo: sec.TRACKING_NO ? sec.TRACKING_NO : "",
        logic: sec.logistic_provider ? sec.logistic_provider.id : "",
        tracLink: sec.logistic_provider
          ? sec.logistic_provider.tracking_link
          : "",
        courierCom: sec.logistic_provider ? sec.logistic_provider.name : "",
        shippingDate: sec.SHIPPING_DATE ? sec.SHIPPING_DATE : "",
        orderDate: sec.ORDER_DATE,
        notes: sec.NOTES ? sec.NOTES : "",
        payStatus: sec.PAYMENT_STATUS ? sec.PAYMENT_STATUS : "",
        // pincode: sec.PINCODE ? sec.PINCODE : "",
        // city: sec.CITY ? sec.CITY : "",
        userData: sec.user,
        state: sec.STATE ? sec.STATE : "",
        contact: sec.CONTACT_NO
          ? sec.CONTACT_NO
          : sec.user
          ? sec.user.MOBILE
          : "",
        productId: sec.course ? sec.course.id : "",
        reference: sec.vender_list ? sec.vender_list.id : "",
        visible: true,
      },
      () => {
        if (this.state.delStatus === "Shipped") {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
              "Tracking Details",
            ],
          });
        } else {
          this.setState({
            options: [
              "Send Login Credentials",
              "Send Product Key",
              "Send Written Message",
            ],
          });
        }
      }
    );
  };

  //  function Dashboard() {
  render() {
    return (
      <div>
        <GridContainer>
          {/* <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p style={{ color: "black" }} className={classes.cardCategory}>
                  Used Space
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  49/50 <small>GB</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} style={{display:"flex"}}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Get more space
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p style={{ color: "black" }} className={classes.cardCategory}>
                  Revenue
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  $34,245
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p style={{ color: "black" }} className={classes.cardCategory}>
                  Fixed Issues
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  75
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <LocalOffer />
                  Tracked from Github
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <i className="fab fa-twitter" />
                </CardIcon>
                <p style={{ color: "black" }} className={classes.cardCategory}>
                  Followers
                </p>
                <h3 style={{ color: "black" }} className={classes.cardTitle}>
                  +245
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem> */}
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <img
                    src={CourseLogo}
                    class="img-fluid rounded-top"
                    alt="course"
                    width={56}
                    height={56}
                  />
                </CardIcon>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:10 }}
                >
                  <p
                    style={{ color: "black", fontSize: 20,fontWeight:"bold" }}
                    className={classes.cardCategory}
                  >
                    Courses
                  </p>
                  <h3 style={{ color: "black",fontWeight:"bold" }} className={classes.cardTitle}>
                    <CountUp
                      start={0}
                      end={this.state.count?.countOfCourses || 0}
                      duration={2}
                    />
                  </h3>
                </div>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <img
                    src={BookLogo}
                    class="img-fluid rounded-top"
                    alt="course"
                    width={56}
                    height={56}
                  />
                </CardIcon>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:10 }}
                >
                  <p
                    style={{ color: "black", fontSize: 20,fontWeight:"bold" }}
                    className={classes.cardCategory}
                  >
                    Books
                  </p>
                  <h3 style={{ color: "black",fontWeight:"bold" }} className={classes.cardTitle}>
                    <CountUp
                      start={0}
                      end={this.state.count?.countOfBooks || 0}
                      duration={2}
                    />
                  </h3>
                </div>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <img
                    src={PremiumUser}
                    class="img-fluid rounded-top"
                    alt="course"
                    width={56}
                    height={56}
                  />
                </CardIcon>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:10 }}
                >
                  <p
                    style={{ color: "black", fontSize: 20,fontWeight:"bold" }}
                    className={classes.cardCategory}
                  >
                    Premium User
                  </p>
                  <h3
                    style={{ color: "black", fontSize: 18,fontWeight:"bold" }}
                    className={classes.cardTitle}
                  >
                    <CountUp
                      start={0}
                      end={this.state.count?.countOfPremiumUsers || 0}
                      duration={2}
                    />
                  </h3>
                </div>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <img
                    src={FreeUser}
                    class="img-fluid rounded-top"
                    alt="course"
                    width={56}
                    height={56}
                  />
                </CardIcon>
                <div
                  style={{ display: "flex", justifyContent: "space-between",marginTop:10 }}
                >
                  <p
                    style={{ color: "black", fontSize: 20,fontWeight:"bold" }}
                    className={classes.cardCategory}
                  >
                    Free User
                  </p>
                  <h3 style={{ color: "black",fontWeight:"bold" }} className={classes.cardTitle}>
                    <CountUp
                      start={0}
                      end={this.state.count?.countOfFreeUsers || 0}
                      duration={2}
                    />
                  </h3>
                </div>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <div style={{ width: "100%", padding: 9 }}>
            <Graph />
          </div>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Language />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Global Sales by Top Locations
                </h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    // position: "absolute",
                    right: "5%",
                    top: "-8%",
                    width: "85px",
                    marginLeft: 50,
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridContainer
                  style={{ position: "relative", left: "10px", top: "20px" }}
                >
                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          // Ensure 'e' has a valid '_d' property before proceeding
                          if (
                            e &&
                            e._d &&
                            e._d instanceof Date &&
                            !isNaN(e._d)
                          ) {
                            let year = e._d.getFullYear().toString();
                            let month = (e._d.getMonth() + 101)
                              .toString()
                              .substring(1);
                            let day = (e._d.getDate() + 100)
                              .toString()
                              .substring(1);
                            this.setState(
                              {
                                frmDate: year + "/" + month + "/" + day,
                              },
                              () => {
                                console.log(
                                  "From Date set to:",
                                  this.state.frmDate
                                );
                              }
                            );
                          } else {
                            console.error("Invalid date selected");
                          }
                        }}
                        inputProps={{
                          required: true,
                          placeholder: "From Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => {
                          let year = e._d.getFullYear().toString();
                          let month = (e._d.getMonth() + 101)
                            .toString()
                            .substring(1);
                          let day = (e._d.getDate() + 100)
                            .toString()
                            .substring(1);
                          this.setState(
                            {
                              toDate: year + "/" + month + "/" + day,
                            },
                            () => {
                              //console.log(this.state.endDate);
                            }
                          );
                        }}
                        inputProps={{
                          required: true,
                          placeholder: "To Date",
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="User's Name,Email or Mobile"
                      id="float"
                      inputProps={{
                        onChange: (e) =>
                          this.setState({ searchOrderId: e.target.value }),
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="Courses">Types</label>
                    <MultiSelect
                      disableSearch
                      options={this.state.types}
                      value={this.state.type}
                      onChange={(data) => {
                        this.setState({ type: data }, () => {
                          console.log(this.state.type);
                          let data = [];
                          this.state.type.forEach((v) => {
                            data.push(v.value);
                          });
                          data.forEach((v) => {
                            if (v === "Course") {
                              this.state.courses.forEach((v) => {
                                this.state.products.push({
                                  label: v.course.TITLE,
                                  value: v.course.ID,
                                });
                              });
                              // if (
                              //   !this.state.products.some(v => v.label === null)
                              // ) {
                              //   this.state.products.push({
                              //     label: null,
                              //     value: null
                              //   });
                              // }
                            }
                            if (v === "Test_Series") {
                              this.state.testSeries.forEach((v) => {
                                this.state.products.push({
                                  label: v.course.TITLE,
                                  value: v.course.ID,
                                });
                              });
                            }
                            if (v === "Books") {
                              this.state.books.forEach((v) => {
                                this.state.products.push({
                                  label: v.course.TITLE,
                                  value: v.course.ID,
                                });
                              });
                            }
                            if (v === "Extend_Validity") {
                              this.state.extensions.forEach((v) => {
                                this.state.products.push({
                                  label: v.course.TITLE,
                                  value: v.course.ID,
                                });
                              });
                            }
                          });
                          let products = this.state.products.filter(
                            (ele, ind) =>
                              ind ===
                              this.state.products.findIndex(
                                (elem) => elem.value === ele.value
                              )
                          );
                          this.setState({
                            products,
                          });
                          if (this.state.type.length > 0) {
                            let typess = [];
                            this.state.type.forEach((v) => {
                              typess.push(v.value);
                            });
                            this.setState({ typess });
                          } else {
                            this.setState({ typess: [] });
                          }
                        });
                      }}
                      labelledBy={"Types"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="Courses">Products</label>
                    <MultiSelect
                      // disableSearch
                      options={this.state.products}
                      value={this.state.product}
                      onChange={(data) => {
                        console.log(data);
                        this.setState({ product: data }, () => {
                          console.log(this.state.product);
                          if (this.state.product.length > 0) {
                            let productss = [];
                            this.state.product.forEach((v) => {
                              productss.push(v.value);
                            });
                            this.setState({ productss });
                          } else {
                            this.setState({ productss: [] });
                          }
                        });
                      }}
                      labelledBy={"Products"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="Courses">Order Types</label>
                    <MultiSelect
                      // disableSearch
                      options={this.state.ordTypes}
                      value={this.state.ordType}
                      onChange={(data) => {
                        console.log(data);
                        this.setState({ ordtype: data, ordType: data });
                      }}
                      labelledBy={"Order Types"}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="Courses">Attempts</label>
                    <MultiSelect
                      // disableSearch
                      options={this.state.attempts}
                      value={this.state.attempt}
                      onChange={(data) => {
                        console.log(data);
                        this.setState({ attempt: data }, () => {
                          console.log(this.state.attempt);
                          if (this.state.attempt.length > 0) {
                            let attemptss = [];
                            this.state.attempt.forEach((v) => {
                              attemptss.push(v.value);
                            });
                            this.setState({ attemptss });
                          } else {
                            this.setState({ attemptss: [] });
                          }
                        });
                      }}
                      labelledBy={"Attempts"}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={3}>
                    <label htmlFor="Courses">Columns</label>
                    <MultiSelect
                      // disableSearch
                      options={this.state.columns}
                      value={this.state.column}
                      onChange={(data) => {
                        console.log(data);
                        this.setState({ column: data }, () => {
                          console.log(this.state.column);
                          if (this.state.column.length > 0) {
                            let columnss = [];
                            this.state.column.forEach((v) => {
                              columnss.push(v.value);
                            });
                            this.setState({ columnss });
                          } else {
                            this.setState({ columnss: [] });
                          }
                        });
                      }}
                      labelledBy={"Columns"}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      style={{ position: "relative", top: "3px" }}
                      onClick={this.searchOrderId}
                      color="success"
                    >
                      {this.state.searching ? "Searching..." : "Search"}
                    </Button>
                  </GridItem>
                  {this.state.download ? (
                    <a
                      href={
                        "https://admin.akclasses.in/api/all_orders_excel_report" +
                        // "http://localhost:8000/api/all_orders_excel_report" +
                        this.state.stringToAttach
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Button
                        style={{ marginTop: "20px", marginRight: "30px" }}
                        color="danger"
                      >
                        Download
                      </Button>
                    </a>
                  ) : null}
                </GridContainer>
                <h3 style={{ marginTop: "30px" }}>Orders</h3>
                <Table
                  tableHead={[
                    "Sr No.",
                    <div key={1} style={{ width: "70px" }}>
                      Order Date
                    </div>,
                    <div key={1} style={{ width: "150px" }}>
                      Product Details
                    </div>,

                    <div key={1} style={{ width: "70px" }}>
                      Student Details
                    </div>,
                    // "Product Key",
                    // "Extended",
                    "Shipping Details",
                    "Actions",
                  ]}
                  tableData={this.state.secondaryCategories.map(
                    (sec, index) => [
                      [index + 1],
                      [
                        sec.ORDER_DATE
                          ? sec.ORDER_DATE.slice(8, 10) +
                            "-" +
                            sec.ORDER_DATE.slice(5, 7) +
                            "-" +
                            sec.ORDER_DATE.slice(0, 4)
                          : sec.order_date.slice(8, 10) +
                            "-" +
                            sec.order_date.slice(5, 7) +
                            "-" +
                            sec.order_date.slice(0, 4),
                      ],
                      [
                        <div key={index}>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Product:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.course ? sec.course.TITLE : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Order Type:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec.ORDER_TYPE}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Media Type:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec.variant
                                ? sec.variant.media_type.media_type
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              OS Type:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec.variant ? sec.variant.os_type.os_type : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Views & Validity:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec.variant
                                ? sec.variant.view_and_validity
                                    .view_and_validity
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Product Key:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec.PRODUCT_KEY ? sec.PRODUCT_KEY : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Attempt:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              {sec?.attempt?.title ? sec.attempt.title : "NA"}
                            </td>
                          </tr>
                        </div>,
                      ],
                      [
                        <div key={index}>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Name.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.name
                                ? sec.name
                                : sec.FIRST_NAME
                                ? sec.FIRST_NAME +
                                  " " +
                                  (sec.LAST_NAME ? sec.LAST_NAME : "")
                                : sec.user
                                ? (sec.user.FIRST_NAME
                                    ? sec.user.FIRST_NAME
                                    : "") +
                                  " " +
                                  (sec.user.LAST_NAME ? sec.user.LAST_NAME : "")
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Email.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.email
                                ? sec.email
                                : sec.EMAIL
                                ? sec.EMAIL
                                : sec.user
                                ? sec.user.email
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Mobile.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.mobile_no
                                ? sec.mobile_no
                                : sec.CONTACT_NO
                                ? sec.CONTACT_NO
                                : sec.user
                                ? sec.user.MOBILE
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Address.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.address
                                ? sec.address
                                : sec.ADDRESS
                                ? sec.ADDRESS
                                : sec.user
                                ? sec.user.ADDRESS_1
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              City.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.CITY
                                ? sec.CITY
                                : sec.user
                                ? sec.user.CITY
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Pin code.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.PINCODE
                                ? sec.PINCODE
                                : sec.user
                                ? sec.user.PINCODE
                                : "NA"}
                            </td>
                          </tr>
                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              PW.:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec.user
                                ? sec.user.DE_PASSWORD
                                  ? sec.user.DE_PASSWORD
                                  : "NA"
                                : "NA"}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid #959595",
                              fontWeight: "bold",
                              color: "#fff",
                              backgroundColor:
                                sec?.user?.TYPE === "Premium" ? "green" : "red",
                            }}
                          >
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                                paddingRight: "5px",
                              }}
                            >
                              Uer Type:
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #959595",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              {sec?.user?.TYPE ? sec.user.TYPE : "NA"}
                            </td>
                          </tr>
                        </div>,
                      ],
                      [
                        sec.DELIVERY_STATUS === "Order Placed" ? (
                          <Button
                            key={index}
                            color="danger"
                            onClick={() => {
                              this.handleModal(sec);
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe558;
                            </i>
                          </Button>
                        ) : sec.DELIVERY_STATUS === "Shipped" ? (
                          <Button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec);
                              this.setState({
                                visible: true,
                                proDetail: false,
                                logDetail: true,
                                import: false,
                                mess: false,
                                mail: false,
                                print: false,
                                delModal: false,
                                comment: false,
                              });
                            }}
                            key={index}
                            color="success"
                          >
                            Shipped
                          </Button>
                        ) : sec.DELIVERY_STATUS === "Packaged" ? (
                          <Button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec);
                            }}
                            key={index}
                            color="success"
                          >
                            {this.state.category === "Extend_Validity"
                              ? "Extended"
                              : "Activated"}
                          </Button>
                        ) : (
                          <Button
                            key={index}
                            color={
                              sec.DELIVERY_STATUS === "Cancelled"
                                ? "danger"
                                : "success"
                            }
                          >
                            {sec.DELIVERY_STATUS}
                          </Button>
                        ),
                      ],
                      [
                        <span key={1}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec, "proDetail");
                            }}
                          >
                            <i className="material-icons">&#xe417;</i>
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec, "comment");
                            }}
                          >
                            <i className="material-icons">&#xe0b9;</i>
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.handleModal(sec, "print");
                            }}
                          >
                            <i className="material-icons">&#xe8ad;</i>
                          </span>
                          <span
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => {
                              this.handleModal(sec, "delModal");
                            }}
                          >
                            <i className="material-icons">&#xe5c9;</i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              bottom: "4px",
                            }}
                            onClick={() => {
                              this.handleModal(sec, "mess");
                            }}
                          >
                            <i className="fab">&#xf39f;</i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.handleModal(sec, "mail");
                            }}
                          >
                            <i className="material-icons">&#xe0be;</i>
                          </span>
                          <br />
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.setState({ statLoading: true });
                              this.handleModal(sec, "showStrategy");
                              axios
                                .get(
                                  // "https://admin.akclasses.in/api/getUserStrategy?ordId=" +
                                  Constants.getUrls.getUserStrat +
                                    "?ordId=" +
                                    sec.ID +
                                    "&user_id=" +
                                    sec.user.ID +
                                    "&course_id=" +
                                    sec.course.ID
                                )
                                .then((resp) => {
                                  console.log(resp);
                                  if (resp.data.status === "success") {
                                    resp.data.notAddedData.forEach((v) => {
                                      this.state.notaddedStrategies.push({
                                        label:
                                          v.strategy.course.TITLE +
                                          " (" +
                                          v.strategy.duration +
                                          " days, " +
                                          v.strategy.strategy_type +
                                          " , " +
                                          v.strategy.type +
                                          ")",
                                        value: v.strategy.id,
                                      });
                                    });

                                    this.setState({
                                      statLoading: false,
                                      addedStrategies:
                                        resp.data.addedData.length > 0
                                          ? resp.data.addedData
                                          : [],
                                      // notaddedStrategies: resp.data.notAddedData.length > 0 ? resp.data.notAddedData : "",
                                    });
                                  } else {
                                    this.setState({
                                      statLoading: false,
                                      addedStrategies: [],
                                      notaddedStrategies: [],
                                    });
                                  }
                                });
                            }}
                          >
                            <i
                              className="fas fa-lightbulb"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              // bottom: "4px"
                            }}
                            onClick={() => {
                              this.handleModal(sec, "import");
                            }}
                          >
                            <i
                              title="Import User"
                              className="fas fa-file-import"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </span>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              position: "relative",
                              bottom: "4px",
                            }}
                            onClick={() => {
    
                              console.log(sec.ID, "❤️");
                              if (sec.course.ftofbatch) {
                                this.handleFetchFbatch(sec.course.ftofbatch,sec.ID);
                                this.setState({
                                  id: sec.ID,
                                  fmodal: true,
                                  fbatchId: sec.course.ftofbatch,
                                });
                              } else {
                                this.setState({
                                  fmodal: true,
                                  fbatchId: "",
                                });
                              }
                            }}
                          >
                            <i className="fab">&#xf2d5;</i>
                          </span>
                        </span>,
                      ],
                    ]
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.secondaryCategories.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
        <Modal
          visible={this.state.visible}
          minWidth="3000"
          minHeight="330"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  {this.state.proDetail ? (
                    <div>
                      <h3>Product Details</h3>
                      <Table
                        tableHead={[
                          "Product Name",
                          "Price",
                          "Product Key",
                          "OS Type",
                          "Media Type",
                          "Views & Validity",
                          // "Tracking No.",
                          // "Shipping Date",
                          "Payment Status",
                          "Delivery Status",
                        ]}
                        tableData={[
                          [
                            this.state.singleProduct,
                            this.state.price,
                            this.state.pKey,
                            this.state.osType,
                            this.state.mediaType,
                            this.state.viewValidity,
                            // this.state.trackingNo,
                            // this.state.shippingDate,
                            this.state.payStatus,
                            this.state.delStatus,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.showStrategy ? (
                    <div>
                      <h5 style={{ textAlign: "center" }}>
                        Do You Want To Add Strategy For This Order?
                      </h5>
                      {this.state.mailSent ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Strategy Added Successfully!
                          </span>
                        </div>
                      ) : null}
                      -
                      {this.state.mailFail ? (
                        <div>
                          <span
                            style={{
                              color: "red",
                              textTransform: "capitalize",
                            }}
                          >
                            {this.state.strMessage}
                          </span>
                        </div>
                      ) : null}
                      {this.state.statLoading ? (
                        <h6 style={{ textAlign: "center" }}>
                          Fetching Strategy Details...
                        </h6>
                      ) : (
                        <div style={{ width: "600px" }}>
                          <label htmlFor="Courses">Strategies</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.notaddedStrategies}
                            value={this.state.notAddedStrats}
                            onChange={(data) => {
                              this.setState({ notAddedStrats: data });
                            }}
                            labelledBy={"Strategies"}
                          />
                          <h6 style={{ textAlign: "center" }}>
                            Alloted Strategies
                          </h6>
                          <Table
                            tableHead={[
                              "Course",
                              "Duration",
                              "Strategy Type",
                              "User Type",
                            ]}
                            tableData={this.state.addedStrategies.map((sec) => [
                              [sec?.strategies?.course?.TITLE],
                              [sec?.strategies?.duration],
                              [sec?.strategies?.strategy_type],
                              [sec?.strategy?.type],
                            ])}
                          />
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <br />
                              <Button
                                className=""
                                color="warning"
                                onClick={this.sendEmail}
                              >
                                {this.state.loading ? <Loader /> : "Yes"}
                              </Button>
                              <Button
                                className=""
                                color="danger"
                                onClick={() => {
                                  this.setState({
                                    visible: false,
                                    showStrategy: false,
                                    notaddedStrategies: [],
                                  });
                                }}
                              >
                                No
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      )}
                    </div>
                  ) : this.state.print ? (
                    <div>
                      <div>
                        <img
                          src="https://akclasses.in/static/media/akLogo.93e45c6ce5fc7e0fbea5.png"
                          alt=""
                          width="200px"
                          style={{
                            paddingBottom: "15px",
                            textAlign: "center",
                            marginLeft: "350px",
                            marginRight: "350px",
                          }}
                        />
                      </div>
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          To,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.student}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.address}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.city},{this.state.state},
                          {this.state.pincode}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {this.state.contact}
                        </span>
                      </div>
                      <br />
                      <div>
                        <h6
                          style={{
                            marginBottom: "0px",
                            fontSize: "20px",
                            fontWeight: "500",
                          }}
                        >
                          From,
                        </h6>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Aman Khedia Classes,
                          <br />
                          The Park Meadows, Flat No. 101,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Plot No. 48, B.U. Kothari Layout Kachimet,
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Amaravati Road, Nagpur. (MH) 440033
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Office: +91 876 657 5808
                        </span>
                      </div>
                      {this.state.printView ? (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.setState({ printView: false });
                                setTimeout(() => {
                                  window.print();
                                }, 1);
                                setTimeout(() => {
                                  this.setState({ printView: true });
                                }, 2);
                              }}
                            >
                              Print
                            </Button>
                            <Button
                              className=""
                              color="danger"
                              onClick={() => {
                                this.closeModal();
                              }}
                            >
                              Cancel
                            </Button>
                          </GridItem>
                        </GridContainer>
                      ) : null}
                    </div>
                  ) : this.state.comment ? (
                    <div>
                      <h3>Add Note</h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Note</label>
                          <br />
                          <CKEditor
                            required={true}
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={this.state.notes}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                notes: data,
                              });
                            }}
                          />
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Note Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.handleSubmit}
                          >
                            {this.state.loading ? <Loader /> : "Add"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.delModal ? (
                    <div>
                      <h3>Are You Sure You Want to Delete It?</h3>
                      {this.state.notifyDelete ? (
                        <div>
                          <span style={{ color: "green" }}>
                            Order Deleted Successfuly!
                          </span>
                        </div>
                      ) : null}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <br />
                          <Button
                            className=""
                            color="warning"
                            onClick={() => {
                              this.handleDelete(this.state.rowId);
                            }}
                          >
                            {this.state.delLoading ? <Loader /> : "Yes"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            No
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.logDetail ? (
                    <div>
                      <h3>Shipping Details</h3>
                      <Table
                        tableHead={[
                          "Courier Company",
                          "Tracking No.",
                          "Track Now",
                          "Update",
                        ]}
                        tableData={[
                          [
                            this.state.courierCom,
                            this.state.trackingNo,
                            <a
                              key={1}
                              href={this.state.tracLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button color="danger">
                                <i
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    margin: "0px",
                                  }}
                                  className="material-icons"
                                >
                                  &#xe558;
                                </i>
                              </Button>
                            </a>,
                            <Button
                              key={1}
                              onClick={() => {
                                this.setState({
                                  visible: true,
                                  proDetail: false,
                                  addShip: false,
                                  print: false,
                                  logDetail: false,
                                  delModal: false,
                                  comment: false,
                                });
                              }}
                              className="btn-round"
                              color="info"
                            >
                              Update
                            </Button>,
                          ],
                        ]}
                      />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.addShip ? (
                    <form>
                      <h4 style={{ textAlign: "center" }}>
                        Order ID:{this.state.orderId}
                      </h4>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              // value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={(e) => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.orders.map((v, i) => (
                                <option
                                  key={i}
                                  value={
                                    v === "Activated" || v === "Extended"
                                      ? "Packaged"
                                      : v
                                  }
                                >
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          {this.state.notification ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Shipping Details Added Successfuly!
                              </span>
                            </div>
                          ) : null}
                          <Button
                            className=""
                            color="warning"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  ) : this.state.import ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Import{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Type</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type1}
                              onChange={(e) => {
                                this.setState({ type1: e.target.value });
                              }}
                            >
                              {this.state.typesss.map((v, i) => (
                                <option key={i} value={v.type}>
                                  {v.type}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                User Imported Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.importUser}
                          >
                            {this.state.loading ? "Importing..." : "Import"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mess ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Message to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>You Want To</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.type2}
                              onChange={(e) => {
                                this.setState({ type2: e.target.value }, () => {
                                  if (
                                    this.state.type2 === "Send Written Message"
                                  ) {
                                    this.setState({ viewMessage: true });
                                  } else {
                                    this.setState({ viewMessage: false });
                                  }
                                });
                              }}
                            >
                              {this.state.options.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        {this.state.viewMessage ? (
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Message</label>
                            <br />
                            <textarea
                              name="message"
                              cols="100"
                              rows="5"
                              onChange={(e) => {
                                this.setState({ message: e.target.value });
                              }}
                            >
                              {this.state.message}
                            </textarea>
                            {this.state.notifyNote ? (
                              <div>
                                <span style={{ color: "green" }}>
                                  Message Sent Successfuly!
                                </span>
                              </div>
                            ) : null}
                          </GridItem>
                        ) : null}

                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMessage}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.mail ? (
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        Send Mail to{" "}
                        <span style={{ color: "purple", fontWeight: "bold" }}>
                          {this.state.student}
                        </span>
                      </h3>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <label>Mail</label>
                          <br />
                          <textarea
                            name="mail"
                            cols="100"
                            rows="5"
                            onChange={(e) => {
                              this.setState({ emailMess: e.target.value });
                            }}
                          >
                            {this.state.message}
                          </textarea>
                          {this.state.notifyNote ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Mail Sent Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.sendMail}
                          >
                            {this.state.loading ? "Sending..." : "Send"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : this.state.extend ? (
                    <div>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Product</label>
                            <select
                              style={{ padding: "11px" }}
                              value={this.state.pro}
                              onChange={(e) => {
                                this.setState({ pro: e.target.value });
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.extensions.map((v, i) => (
                                <option key={i} value={v.ID}>
                                  {v.TITLE}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ pkey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>
                                {this.state.message + "!!!"}
                              </span>
                            </div>
                          ) : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <Button
                            className=""
                            color="warning"
                            onClick={this.applyKey}
                          >
                            {this.state.loading ? "Submitting..." : "Submit"}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.setState({ visible: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <form>
                      <h4 style={{ textAlign: "center" }}>
                        Update Shipping Details
                      </h4>

                      <GridContainer>
                        {/*   <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Product Name :" + this.state.product}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={12} md={6}>
                          <CustomInput
                            labelText={"Student Name :" + this.state.student}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Address :" + this.state.address}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={"Producy Key :" + this.state.pKey}
                            id="disabled"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true
                            }}
                          />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Logistics</label>
                            <select
                              style={{ padding: "11px" }}
                              name="logic"
                              value={this.state.logic}
                              onChange={this.handleInput}
                            >
                              <option value="">Select</option>
                              {this.state.logistics.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                        </GridItem>
                        {/*  <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <label>Order Status</label>
                            <select
                              style={{ padding: "11px" }}
                              name="delStatus"
                              value={this.state.delStatus}
                              onChange={e => {
                                this.handleSelect(
                                  e,
                                  this.state.orderId,
                                  this.state.rowId
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {orders.map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          {this.state.notifyStatus ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Status Changed Successfuly!
                              </span>
                            </div>
                          ) : null}
                        </GridItem> */}

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Tracking ID"
                            id="float"
                            inputProps={{
                              disabled: false,
                              value: this.state.trackingNo,
                              onChange: (e) =>
                                this.setState({ trackingNo: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Product Key"
                            id="float"
                            inputProps={{
                              disabled: false,
                              value: this.state.pKey,
                              onChange: (e) =>
                                this.setState({ pKey: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {this.state.notification ? (
                            <div>
                              <span style={{ color: "green" }}>
                                Shipping Details Updated Successfuly!
                              </span>
                            </div>
                          ) : null}
                          <Button
                            className=""
                            color="info"
                            onClick={this.addShipping}
                          >
                            {this.state.loading ? (
                              <Loader />
                            ) : (
                              <i
                                style={{
                                  color: "white",
                                  fontSize: "18px",
                                  margin: "0px",
                                }}
                                className="material-icons"
                              >
                                &#xe558;
                              </i>
                            )}
                          </Button>
                          <Button
                            className=""
                            color="danger"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            <i
                              style={{
                                color: "white",
                                fontSize: "18px",
                                margin: "0px",
                              }}
                              className="material-icons"
                            >
                              &#xe5c9;
                            </i>
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Modal>

        <Modal
          visible={this.state.fmodal}
          width="500px"
          height="330px"
          effect="fadeInUp"
          onClickAway={() => {
            this.setState({ fmodal: false });
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {this.state.fbatchId ? (
              <div>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                  Face-to-Face Allotted Batch: {this.state.fbatchName}
                </h2>
                <button
                  style={{
                    padding: "10px 20px",
                    margin: "0 auto",
                    display: "block",
                    backgroundColor: "#f44336",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ fmodal: false });
                  }}
                >
                  Close
                </button>
              </div>
            ) : (
              <div>
                <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                  Allot Face-to-Face Batch
                </h3>
                <FormControl fullWidth>
                  <select
                    id="batch-select"
                    style={{
                      padding: "11px",
                      margin: "8px 0 20px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    value={this.state.selectedBatchId}
                    onChange={this.handleBatchChange}
                  >
                    <option value="" disabled>
                      Select Batch
                    </option>
                    {this.state.faceToFaceBatchList.map((batch) => (
                      <option key={batch.id} value={batch.id}>
                        {batch.batch_name}
                      </option>
                    ))}
                  </select>
                </FormControl>
                <div style={{ display: "flex", gap: 40 }}>
                  <button
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#4caf50",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const { id, selectedBatchId } = this.state;
                      if (!selectedBatchId) {
                        alert("Please select a batch before proceeding.");
                        return;
                      }
                      console.log(id,"🐒")
                      this.handleFaceToFace(id);
                    }}
                  >
                    Allot
                  </button>

                  <button
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#f44336",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ fmodal: false });
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default Dashboard;
