import React, { Component } from "react";
import Table from "components/Table/Table.js";
import axios from "axios";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "components/CustomButtons/Button.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Box, Typography, TextField } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput.js";
const classes = makeStyles(styles);
const perPages = ["10", "25", "50", "100","200"];

class Results extends Component {
  state = {
    notifyStatus: false,
    empty: false,
    secondaryCategories: [],
    results: [],
    notAttempted: [],
    test: 0,
    status: "",
    active: 1,
    per_page: "10",
    currentPage: 1,
    prevPage: 0,
    nextPage: 0,
    lastPage: 0,
    lastPageView: false,
    searchClicked: false,
    firstPageView: false,
    total: 0,
    from: 0,
    to: 0,
    fromInc: 1,
    toInc: 5,
    btnIdArray: [],
    filteredBtnIdArray: [],
    not_attempted: false,
    allParticipants: [],
    is_attempt: "",
    searchQuery: "",
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.handleSearch(id);
  }

  handleSearch = (id, search = "") => {
    axios
      .get(
        `https://admin.akclasses.in/api/single_test_series_report?test_series_id=${id}&per_page=${this.state.per_page}&search=${search}`
      )
      .then((resp) => {
        const { data, last_page, total, from, to } = resp.data.records;

        let btnIdArray = [];
        for (let i = 1; i <= last_page; i++) {
          btnIdArray.push(i);
        }

        this.setState(
          {
            btnIdArray,
            results: data,
            total,
            from,
            to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery }, () => {
      const { id } = this.props.match.params;
      this.handleSearch(id, this.state.searchQuery);
    });
  };

  currentPage = (v,search = "") => {
    const { id } = this.props.match.params;
    let commonUrl =
      `https://admin.akclasses.in/api/single_test_series_report?test_series_id=${id}&per_page=${this.state.per_page}&search=${search}`
    axios.get(commonUrl + v).then((resp) => {
      // console.log(resp.data.data);
      this.setState({
        total: resp.data.total,
        from: resp.data.from,
        to: resp.data.to,
        results: resp.data.data,
        active: v,
      });
    });
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleNotAttempted = (e) => {
    let is_attempt = "0";
    if (this.state.not_attempted === true) {
      is_attempt = "0";
      this.setState({ is_attempt: "0" });
      console.log("true");
    } else {
      is_attempt = "";
    }
    axios
      .get(
        "https://admin.akclasses.in/api/single_test_series_report?test_series_id=" +
          this.state.test +
          "&is_attempt=" +
          is_attempt
        //  +
        // this.state.is_attempt
      )
      .then((resp) => {
        console.log(resp.data.records.total);
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.records.last_page; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            results: resp.data.records.data,
            total: resp.data.records.total,
            from: resp.data.records.from,
            to: resp.data.records.to,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
        this.setState({
          // results: resp.data.records.data,
          // notAttempted: resp.data.not_attempted,
          searchClicked: false,
        });
      });
  };

  handlePage = (e) => {
    const newPerPage = e.target.value;
    const { id } = this.props.match.params;
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        toInc: 5,
      },
      () => {
        axios
          .get(
              `https://admin.akclasses.in/api/single_test_series_report?test_series_id=${id}&per_page=${this.state.per_page}&search=`
          )
          .then((resp) => {
            console.log(resp);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.records.last_page; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                results: resp.data.records.data,
                total: resp.data.records.total,
                from: resp.data.records.from,
                to: resp.data.records.to,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };
  currentPage = (v,search = "") => {
    const { id } = this.props.match.params;
    let commonUrl =
    `https://admin.akclasses.in/api/single_test_series_report?test_series_id=120&per_page=${this.state.per_page}&search=&page=`
    axios.get(commonUrl+v).then((resp) => {
      console.log(resp);
      let obj_to_array = Object.entries(resp.data.records.data).map((obj) => ({
        key_name: obj[1],
      }));
      // console.log(obj_to_array);
      this.setState({ results: [] });
      obj_to_array.map((sec, idx) => {
        this.state.results.push(sec.key_name);

        if (idx === obj_to_array.length - 1) {
          this.setState(
            {
              results: this.state.results,
            },
            () => {
              console.log(this.state.results);
            }
          );
        }
      });
      this.setState({
        // results: resp.data.records.data,
        total: resp.data.records.total,
        from: resp.data.records.from,
        to: resp.data.records.to,
        active: v,
      });
    });
  };

  render() {
    const { results, searchQuery } = this.state;
    return (
      <div>
        <GridContainer>
          {this.state.notifyStatus ? (
            <div style={{ position: "relative", left: "600px" }}>
              <SnackbarContent
                message={"Status Changed!"}
                icon={AddAlert}
                color="success"
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="danger">
                  <Assignment />
                </CardIcon>
                <h4>Simple Table</h4>
              </CardHeader>
              <CardBody style={{ position: "relative" }}>
                <FormControl
                  style={{
                    position: "absolute",
                    right: "10%",
                    width: "85px",
                  }}
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Per Page
                  </InputLabel>
                  <Select
                    s
                    key={1}
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.per_page}
                    inputProps={{
                      name: "per_page",
                      id: "simple-select",
                      onChange: (e) => {
                        this.handlePage(e);
                      },
                    }}
                  >
                    {perPages.map((v, i) => (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <Button
                      style={{
                        position: "relative",
                        top: "9px",
                        right: "20px",
                      }}
                      onClick={(e) => {
                        this.setState(
                          {
                            not_attempted: !this.state.not_attempted,
                          },
                          () => {
                            this.handleNotAttempted(e);
                          }
                        );
                      }}
                      className="btn-round"
                      color="warning"
                    >
                      {this.state.not_attempted ? "Attempted" : "Not-Attempted"}
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={"Search"}
                    id="float"
                    inputProps={{
                      onChange: (e) =>
                        this.setState({ searchQuery: e.target.value }),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                  <Button
                    onClick={() => {
                      const { id } = this.props.match.params;
                      this.handleSearch(id, this.state.searchQuery); // Trigger search on click
                    }}
                    style={{
                      position: "relative",
                      bottom: "62px",
                      left: "200px",
                    }}
                    color="success"
                  >
                    Search
                  </Button>
                </GridItem>
                </GridContainer>
               

                <h3>Results</h3>
                <Table
                  tableHead={[
                    "Sr No.",
                    "Name",
                    "Email",
                    "Mobile",
                    "Total",
                    "Time",
                  ]}
                  tableData={results.map((sec, index) => [
                    [index + 1],
                    [sec.user?.FIRST_NAME],
                    [sec.user?.email],
                    [sec.user?.MOBILE],
                    [sec.total ? sec.total : "Not Attempted"],
                    [sec.time ? sec.time : "Not Attempted"],
                  ])}
                />
                {/* )} */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.results.length === 0 ? (
          <div>
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          </div>
        ) : this.state.empty ? null : (
          <div>
            <div style={{ float: "left" }}>
              Showing {this.state.from} to {this.state.to} of {this.state.total}{" "}
              entries.
            </div>
            <div style={{ float: "right", border: "1px solid grey" }}>
              <div
                onClick={this.prevPages}
                style={{
                  width: "34px",
                  textAlign: "center",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c4;
                </i>
              </div>
              {this.state.filteredBtnIdArray.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    this.currentPage(v);
                  }}
                  style={{
                    width: "34px",
                    textAlign: "center",
                    borderLeft:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 4)
                        ? ""
                        : "1px solid grey",
                    borderRight:
                      (v % 2 === 0 && (i === 1 || i === 2 || i === 3)) ||
                      (v % 2 === 0 && i === 0)
                        ? ""
                        : "1px solid grey",
                    backgroundColor:
                      this.state.active === v ? "skyblue" : "transparent",
                    padding: "5px 0px 5px 0px",
                    fontWeight: this.state.active === v ? "bold" : "",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {v}
                </div>
              ))}
              <div
                onClick={this.nextPages}
                style={{
                  width: "34px",
                  borderLeft:
                    this.state.filteredBtnIdArray.length === 2 ||
                    this.state.filteredBtnIdArray.length === 4
                      ? "1px solid grey"
                      : "",
                  padding: "4px 0px 4px 0px",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  fontWeight: "bold",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "grey",
                }}
              >
                <i
                  style={{ fontSize: "16px", position: "relative", top: "3px" }}
                  className="material-icons"
                >
                  &#xe5c8;
                </i>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Results;
