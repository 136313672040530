import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Constants from "variables/Constants";
import { Link } from "react-router-dom";
import { DateRangePicker } from "materialui-daterange-picker";


const useStyles = makeStyles(styles);

const Tracker = () => {
  const classes = useStyles();
  const [trackerData, setTrackerData] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [subjectId, setSubjectId] = useState([]);
  const [subject, setSubject] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [trackerId, setTrackerId] = useState("");
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [createSnackBar, setCreateSnackBar] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);


  useEffect(() => {
    fetchTracker();
    fetchAllChapter();
  }, []);

  const fetchTracker = async () => {
    setLoading(true);
    try {
      const res = await axios.get(Constants.tracker.fetchTracker);
      setTrackerData(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const toggle = () => setOpen((prevOpen) => !prevOpen);

  const fetchAllChapter = async () => {
    try {
      const res = await axios.get(Constants.TrackerChapter.getAll);
      const chaptersData = res.data.data;
  
    
      const validChaptersData = chaptersData.filter(item => item.subject && item.subject.id);
  
      const uniqueSubjects = Array.from(
        new Set(validChaptersData.map((item) => item.subject.id))
      ).map((id) =>
        validChaptersData.find((item) => item.subject.id === id).subject
      );
  
      setSubject(uniqueSubjects);
  
      const chaptersWithSubChapters = validChaptersData.map((chapter) => ({
        chapterId: chapter.id,
        subjectId: chapter.subject.id,
        subChapters: chapter.sub_chapters.map((sub) => sub.id),
      }));
  
      setChapters(chaptersWithSubChapters);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payLoad = {
      title: title,
      description: description,
      subjects: subjectId.map((id) => ({
        id: Number(id),
        chapters: chapters
          .filter((chapter) => chapter.subjectId === id)
          .map((chapter) => ({
            id: chapter.chapterId,
            sub_chapters: chapter.subChapters,
          })),
      })),
    };

    try {
      await axios.post(Constants.tracker.postTracker, payLoad);
      setCreateSnackBar(true);
      setOpenCreate(false);
      fetchTracker();
      resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setSubjectId([]);
  };

  const confirmDelete = (id) => {
    setTrackerId(id);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(Constants.tracker.fetchTracker + "/" + trackerId);
      setDeleteSnackbarOpen(true);
      setOpenDeleteConfirm(false);
      fetchTracker();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseUpdate = () => {
    resetForm();
    setOpenUpdate(false);
  };

  const handleOpenUpdateModal = (tracker) => {
    setTitle(tracker.trackers?.[0]?.title || "");
    setDescription(tracker.trackers?.[0]?.description || "");
    setSubjectId(tracker.subjects?.[0]?.id ? [tracker.subjects[0].id] : []);
    setTrackerId(tracker.tracking_id);
    setOpenUpdate(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      subjects: subjectId.map((id) => ({
        id: Number(id),
        chapters: chapters
          .filter((chapter) => chapter.subjectId === id)
          .map((chapter) => ({
            id: chapter.chapterId,
            sub_chapters: chapter.subChapters,
          })),
      })),
    };

    try {
      await axios.post(Constants.tracker.fetchTracker + "/" + trackerId,
        payLoad);
      fetchTracker();
      handleCloseUpdate();
    } catch (error) {
      console.error(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const Loader = () => (
    <div style={loaderStyles.container}>
      <div style={loaderStyles.spinner}></div>
    </div>
  );

  const loaderStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      border: "8px solid #f3f3f3",
      borderTop: "8px solid #3498db",
      borderRadius: "50%",
      width: "60px",
      height: "60px",
      animation: "spin 1s linear infinite",
    },
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal
            open={openCreate}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-title" variant="h6" component="h2">
                Tracker Form
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <label>Subject</label>
                  <Select
                    multiple
                    value={subjectId}
                    onChange={(e) => setSubjectId(e.target.value)}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) => subject.find((item) => item.id === id)?.title
                        )
                        .join(", ")
                    }
                  >
                    {subject.map((item) => (
                      <MenuItem key={item .id} value={item.id}>
                        <Checkbox checked={subjectId.includes(item.id)} />
                        <label>{item.title}</label>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={title}
                  required
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  label="Weightage"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={description}
                  required
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Button color="info" type="submit" sx={{ mt: 2 }}>
                  Submit
                </Button>
                <Button
                  color="rose"
                  onClick={handleClose}
                  sx={{ mt: 2, ml: 1 }}
                >
                  Cancel
                </Button>
              </form>
            </Box>
          </Modal>

          <Modal
            open={openUpdate}
            onClose={handleCloseUpdate}
            aria-labelledby="update-modal-title"
            aria-describedby="update-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="update-modal-title" variant="h6" component="h2">
                Update Tracker
              </Typography>
              <form onSubmit={handleUpdate}>
                <FormControl fullWidth>
                  <label>Subject</label>
                  <Select
                    multiple
                    value={subjectId}
                    onChange={(e) => setSubjectId(e.target.value)}
                    renderValue={(selected) =>
                      selected
                        .map((id) => subject.find((item) => item.id === id)?.title)
                        .join(", ")
                    }
                  >
                    {subject.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={subjectId.includes(item.id)} />
                        <label>{item.title}</label>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <TextField
                  label="Weightage"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Button
                  className="btn-round"
                  color="info"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Update
                </Button>
                <Button
                  className="btn-round"
                  color="rose"
                  variant="contained"
                  sx={{ mt: 2, ml: 1 }}
                  onClick={handleCloseUpdate}
                >
                  Cancel
                </Button>
              </form>
            </Box>
          </Modal>

          <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
            <Box sx={modalStyle}>
              <Typography variant="h6">Confirm Deletion</Typography>
              <Typography>Are you sure you want to delete this tracker?</Typography>
              <Button color="success" onClick={handleDelete}>
                Confirm
              </Button>
              <Button color="rose" onClick={handleCloseDeleteConfirm}>
                Cancel
              </Button>
            </Box>
          </Modal>
          <Snackbar
            open={deleteSnackbarOpen}
            autoHideDuration={3000}
            onClose={() => setDeleteSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
            message="Deleted Successfully"
            ContentProps={{
              sx: {
                backgroundColor: "green",
              },
            }}
          />
          <Snackbar
            open={createSnackBar}
            autoHideDuration={3000}
            onClose={() => setCreateSnackBar(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
            message="Created Successfully"
            ContentProps={{
              sx: {
                backgroundColor: "green",
              },
            }}
          />
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  < CardIcon color="danger"></CardIcon>
                </CardHeader>
                <CardBody>
                  <Button color="success" onClick={() => setOpenCreate(true)}>
                    ADD Tracker
                  </Button>
                  <Link to="/admin/allotedUser">
                    <Button color="warning">Tracker Alloted User</Button>
                  </Link>
                  <Table
                    tableHead={[
                      "ID",
                      "TITLE",
                      "Weightage",
                      "SUBJECT NAME",
                      "CHAPTER",
                      "SUB CHAPTER",
                      "ACTION",
                    ]}
                    tableData={trackerData.map((item, index) => [
                      index + 1,
                      item.trackers.length > 0 ? item.trackers[0].title : "N/A",
                      item.trackers.length > 0
                        ? item.trackers[0].description
                        : "N/A",
                      item.subjects.length > 0
                        ? item.subjects
                            .map((subject) => subject.title)
                            .join(", ")
                        : "N/A",
                      item.chapters.length > 0
                        ? item.chapters
                            .map((chapter) => chapter.title)
                            .join(",")
                        : "N/A",
                      item.subchapters.length > 0
                        ? item.subchapters.map((sub) => sub.title).join(",")
                        : "N/A",
                      [
                        <Button
                          key={`update-${index}`}
                          onClick={() => handleOpenUpdateModal(item)}
                          style={{ backgroundColor: "#f72585" }}
                        >
                          Update
                        </Button>,
                        <Button
                          key={`delete-${index}`}
                          onClick={() => confirmDelete(item.tracking_id)}
                          style={{ backgroundColor: "#3a86ff" }}
                        >
                          Delete
                        </Button>,
                      ],
                    ])}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
    </div>
  );
};

export default Tracker;