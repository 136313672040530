import React from 'react'
import {Link} from "react-router-dom"

const Test2 = () => {
  return (
    <div>
        <Link to="/admin/test1">
        go to test1
        </Link>
        Test2
        </div>
  )
}

export default Test2