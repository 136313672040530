import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Table from "components/Table/Table.js";
import Constants from "variables/Constants";

const Ftof = () => {
  const [open, setOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ftofbatches, setFtofBatches] = useState([]);
  const [queryResults, setQueryResults] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [batch, setBatch] = useState("");

  useEffect(() => {
    fetchData();
    fetchOrderReport();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://admin.akclasses.in/api/ftof_batches_days/get_ftof_batches"
      );
      setFtofBatches(res.data.data);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const fetchOrderReport = async () => {
    const params = {
      order_type: JSON.stringify(["Online", "Offline"]),
      per_page: 10,
      user: "mayur",
    };

    const queryString = new URLSearchParams(params).toString();
    const apiUrl = `https://admin.akclasses.in/api/all_order_report?${queryString}`;

    try {
      const response = await axios.post(apiUrl);
      setQueryResults(response.data.data.data || []);
    } catch (error) {
      console.error("Error fetching order report:", error);
    }
  };

  const faceToFace = async (batchId) => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/getSingleFbatch/${batchId}`
      );
      setBatch(res.data.data.batch_name || "No batch found");
    } catch (error) {
      console.error("Error fetching Face-to-Face Batch Data:", error);
      setBatch("");
    }
  };

  const handleOpenModal = (item) => {
    const ftofbatchId = item.course?.ftofbatch;
    setSelectedItem(item);

    if (ftofbatchId) {
      faceToFace(ftofbatchId);
    } else {
      setBatch("");
    }

    setOpen(true);
  };

  const handleConfirmAllotment = () => {
    setConfirmModalOpen(true);
  };

  const confirmAllotFToFBatch = async () => {
    if (!selectedBatchId) {
      alert("Please select a batch to allot.");
      return;
    }

    setLoading(true);

    try {
      const payload = { ftofbatch: selectedBatchId };
      const apiUrl = `${Constants.postUrls.updateFtoFBatchonline}/${selectedItem.id}`;
      await axios.post(apiUrl, payload);

      fetchOrderReport();
      setOpen(false);
      setConfirmModalOpen(false);
    } catch (error) {
      console.error("Error allotting batch:", error);
      alert("Failed to allot batch. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      {/* Open Modal Button at the top */}
      <div style={{ marginBottom: "16px", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Open Modal
        </Button>
      </div>
  
      {/* Table Component */}
      <Table
        tableHead={["ID", "Product Details", "Student Details", "Action"]}
        tableData={queryResults.map((item, index) => [
          index + 1,
          item.ORDER_TYPE,
          item.FIRST_NAME,
          [
            <Button
              variant="contained"
              color="secondary"
              key={`open-modal-${index}`}
              onClick={() => handleOpenModal(item)}
            >
              Open Modal
            </Button>,
          ],
        ])}
      />
  
      
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={style}>
              {batch ? (
                <Typography variant="h6" id="modal-title">
                  Allotted Face-To-Face Batch: {batch}
                </Typography>
              ) : (
                <>
                  <Typography variant="h6" id="modal-title">
                    Allot Face-To-Face Batch
                  </Typography>
                  <FormControl fullWidth>
                    <select
                      id="batch-select"
                      style={{ padding: "11px", marginTop: "8px" }}
                      value={selectedBatchId || ""}
                      onChange={(e) => setSelectedBatchId(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Batch
                      </option>
                      {ftofbatches.map((batch) => (
                        <option key={batch.id} value={batch.id}>
                          {batch.batch_name}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <div style={{ marginTop: "16px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleConfirmAllotment}
                      disabled={loading}
                      style={{ marginRight: "8px" }}
                    >
                      {loading ? "Allotting..." : "Allot"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Modal>
  
  
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        aria-labelledby="confirm-modal-title"
        aria-describedby="confirm-modal-description"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={style}>
              <Typography variant="h6" id="confirm-modal-title">
                Confirm Batch Allotment
              </Typography>
              <Typography>
                Are you sure you want to allot this batch?
              </Typography>
              <div style={{ marginTop: "16px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={confirmAllotFToFBatch}
                  disabled={loading}
                  style={{ marginRight: "8px" }}
                >
                  {loading ? "Allotting..." : "Confirm"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setConfirmModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
  
};

export default Ftof;
