const mainDomain = "https://admin.akclasses.in/";
const imgDomain = "https://admin.akclasses.in";
// const mainDomain = "http://localhost:8000/";
// const imgDomain = "http://localhost:8000";
const Constants = {
  imgUrl: mainDomain,
  imgDomain: imgDomain,
  getUrls: {
    getManualNotification: mainDomain + "api/onesignal-notification/getAll",
    showVersion: "https://core.akclasses.in/" + "api/showVersion",
    mobileSlidingImages: mainDomain + "api/mobileSlidingImages",
    getSingleMobileSlidingImage: mainDomain + "api/mobileSlidingImage/",
    all_stud_day_strategy: mainDomain + "api/all_stud_day_strategy",
    get_alluser: mainDomain + "api/get_alluser",
    getOldUsers: mainDomain + "api/getOldUsers",
    new_order: mainDomain + "api/new_order",
    faculty: mainDomain + "api/faculty",
    get_type: mainDomain + "api/get_type",
    strategy: mainDomain + "api/strategy",
    course_with_variant: mainDomain + "api/course_with_variant",
    logistic: mainDomain + "api/logistic",
    couponCodes: mainDomain + "api/couponCodes",
    getCoursesVarient: mainDomain + "api/course/",
    getCoursesActiveVarient: mainDomain + "api/courseActiveVarients/",
    getCourses: mainDomain + "api/courses",
    categories: mainDomain + "api/categories",
    get_test_series: mainDomain + "api/get_test_series",
    get_single_test_series: mainDomain + "api/get_single_test_series/",
    get_single_course: mainDomain + "api/course/",
    showSingleCourse: mainDomain + "api/showSingleCourse/",
    getNotaddedQuestions: mainDomain + "api/getNotaddedQuestions",
    subCategories: mainDomain + "api/subCategories",
    courseTypes: mainDomain + "api/courseTypes",
    complexityLevels: mainDomain + "api/complexityLevels",
    faculties: mainDomain + "api/faculties",
    tags: mainDomain + "api/tags",
    deliveryModes: mainDomain + "api/deliveryModes",
    pageContents: mainDomain + "api/pageContents",
    pageLinks: mainDomain + "api/pageLinks",
    products: mainDomain + "api/products",
    courseFeatures: mainDomain + "api/courseFeatures",
    courseContents: mainDomain + "api/courseContents",
    generateCourseId: mainDomain + "api/courseId/generate",
    //Question Bank APIs
    getSeriesQuestions: mainDomain + "api/get_series_questions/",
    getSubjectQuestions: mainDomain + "api/paperQuestions/",
    getLiveQuizQuestions: mainDomain + "api/getLiveQuizQuestions/",
    getQuestionBank: mainDomain + "api/getquestionbank",
    getQuestionBankData: mainDomain + "api/getQuestionBankData",
    getQuestionBankLevel: mainDomain + "api/getlevel",
    getQuestionBankLevelData: mainDomain + "api/getLevelsData",
    getQuestionBankSubjectData: mainDomain + "api/getSubjectData",
    getQuestionBankSubject: mainDomain + "api/getSubject",
    getQuestionBankChapter: mainDomain + "api/getchapter",
    getQuestionBankChapterData: mainDomain + "api/getchapterData",
    showLevel_with_subject: mainDomain + "api/showLevel_with_subject/",
    show_subject: mainDomain + "api/show_subject/",
    show_with_levels: mainDomain + "api/show_with_levels/",
    //Scanner Api
    getquestionn: mainDomain + "api/scanner/getquestionn",
    scannerSubjectData: mainDomain + "api/scanner/scannerSubjectData/",
    getlevel_with_paginate: mainDomain + "api/scanner/getlevel_with_paginate",
    getScannersubject: mainDomain + "api/scanner/getScannersubject",
    getShowScannersubject: mainDomain + "api/scanner/showSubjectData/",
    restoreScanDeleteQuestion: mainDomain + "api/scanner/restorescannreque",
    getScannSubjectData: mainDomain + "api/scanner/scannerSubject",

    getchapter: mainDomain + "api/scanner/getchapter",
    getlevel_without_paginate:
      mainDomain + "api/scanner/getlevel_without_paginate",
    subject_with_levels: mainDomain + "api/scanner/subject_with_levels/",
    scannerActivateSources: mainDomain + "api/scanner/scanneractivateSources",
    getScannerSource: mainDomain + "api/scanner/getscannersource",
    offlineOrders: mainDomain + "api/offlineOrders",
    downloadOfflinePdf: mainDomain + "api/downloadOfflinePdf/",
    downloadOnlinePdf: mainDomain + "api/downloadOnlinePdf/",
    showTestimonials: mainDomain + "api/testimonials",
    getOnlineOrders: mainDomain + "api/online_order_get",
    courseCategoriesR: mainDomain + "api/courseCategoriesR",
    vender: mainDomain + "api/vender",
    facultyR: mainDomain + "api/facultyR",
    coursesR: mainDomain + "api/coursesR",
    attempt: mainDomain + "api/attempt",
    venderR: mainDomain + "api/venderR",
    testCategoriesR: mainDomain + "api/testCategoriesR",
    index_book_pagi: mainDomain + "api/index_book_pagi",
    level: mainDomain + "api/level/",
    get_by_cate_levl_strm: mainDomain + "api/get_by_cate_levl_strm",
    generate_orderId: mainDomain + "api/generate_orderId",
    expenseCategoriesR: mainDomain + "api/expenseCategoriesR",
    showCategory_with_level: mainDomain + "api/showCategory_with_level/",
    get_vendor_product: mainDomain + "api/get_vendor_product/",
    get_product_by_stream: mainDomain + "api/get_product_by_stream",
    userdetail: mainDomain + "api/userdetail",
    topic: mainDomain + "api/topic",
    show_all_questions: mainDomain + "api/show_questions/",
    getUserStrategy: mainDomain + "api/getUserStrategy",
    getUserStrat: mainDomain + "api/getUserStrat",
    fileDownload: mainDomain + "api/fileDownload",
    getBatches: mainDomain + "api/batches",
    getAllotedAmenities: mainDomain + "api/show_alloted_amenities",
    getAmenities: mainDomain + "api/amenities",
    getSources: mainDomain + "api/sources",
    getSingleSource: mainDomain + "api/sources/",
    getActivateSources: mainDomain + "api/getActivateSources",
    getCourseWings: mainDomain + "api/courseWings",
    getBatchWings: mainDomain + "api/batchWings",
    getSingleAmenities: mainDomain + "api/amenities/",
    getCourseStrategies: mainDomain + "api/assignCourseStrategy",
    getsubjects: mainDomain + "api/subjects",
    getuserStrategy: mainDomain + "api/userStrategy",
    getolduserStrategy: mainDomain + "api/userStrategy_old",
    getFaceToFace: mainDomain + "api/facetoface",
    getFaceToFaceOrder: mainDomain + "api/FaceToFaceOrder",
    getFaceToFaceOfflineOrder: mainDomain + "api/facetofaceOfflineOrders",
    getCourseCommission: mainDomain + "api/courseCommission",
    subjectFilter: mainDomain + "api/subject-filter",
    faceToFaceBatch: mainDomain + "api/ftof_batches_days/get_ftof_batches",
    getSingleFbatch: mainDomain + "api/getSingleFbatch/",
    facetoaceBatchDays: mainDomain + "api/ftof_batches_days/get_batch_days",
    createQrCode: mainDomain + "api/ftof_batches_days/create_qrcode/",
    getFaceBatchStudents:
      mainDomain + "api/ftof_batches/get-ftof-batchestudents",
    deleteFaceBatch: mainDomain + "api/ftof_batches/delete/",
    restoreDeleteQuestion: mainDomain + "api/restorequestionbank",
    getCustomTestSeries: mainDomain + "api/custom/test-series/getAll",
    getSingleCustomTest:
      mainDomain + "api/custom/test-series/getSingleCustomTest/",
    current_page_data: mainDomain + "api/custom/test-series/current_page_data/",
    vsmartOrders: mainDomain + "api/getvsmartorder",
    getDeviceInfo: mainDomain + "api/getDeviceInfo",
    getIncomeTypes: mainDomain + "api/getIncomeTypes",
    getOfflineExtensionOrders: mainDomain + "api/getOfflineExtensionOrders",
    getOnlineExtensionOrders: mainDomain + "api/getOnlineExtensionOrders",
    getmailTemplate: mainDomain + "api/mailTemplate",
    getOtps: mainDomain + "api/getOtps",
    getAllDocuments: mainDomain + "api/chapterDocuments/getAllCourseDocuments",
  },
  postUrls: {
    allotTestDaysToStudent: mainDomain + "api/allotTestDaysToStudent",
    deleteOrdersSheetData: mainDomain + "api/deleteOrdersSheetData",
    updateOrdersSpreadsheet: mainDomain + "api/updateSpreadsheet",
    addExtensionOfflineOrder: mainDomain + "api/addExtensionOfflineOrder",
    addExtensionOnlineOrder: mainDomain + "api/addExtensionOnlineOrder",
    addIncomeType: mainDomain + "api/addIncomeType",
    updateIncomeType: mainDomain + "api/updateIncomeType/",
    changeIncomeTypeStatus: mainDomain + "api/changeIncomeTypeStatus/",
    deleteIncomeType: mainDomain + "api/deleteIncomeType/",
    storeAnnouncement: mainDomain + "api/storeAnnouncement",
    deleteSheetData: mainDomain + "api/monitoring/deleteSheetData",
    updateSpreadsheet: mainDomain + "api/monitoring/updateBatchesSheet_Updated",
    getFtoFAllotNotAllotTest: mainDomain + "api/getFtoFAllotNotAllotTest",
    allotFtoFTestSeries: mainDomain + "api/allotFtoFTestSeries",
    saveDeviceInfo: mainDomain + "api/saveDeviceInfo",
    allotTestSeries: mainDomain + "api/allotTestSeries",
    getAllotNotAllotTest: mainDomain + "api/getAllotNotAllotTest",
    checkAllotOrNot: mainDomain + "api/checkAllotOrNot",
    checkAllotedTestExam: mainDomain + "api/checkAllotedTestExam",
    importStudentData: mainDomain + "api/importStudentData",
    change_code_status: mainDomain + "api/change_code_status/",
    create_code: mainDomain + "api/create_code/",
    updateFtoFBatchonline: mainDomain + "api/updateFtoFBatchonline/",
    updateFtoFBatchoffline: mainDomain + "api/updateFtoFBatchoffline/",
    add_ftofbatch: mainDomain + "api/add_ftofbatch",
    addQrValidityDate: mainDomain + "api/addQrValidityDate",
    deleteBatchStudents: mainDomain + "api/deleteBatchStudents/",
    uploadQuestionDocx: mainDomain + "api/question/upload",
    uploadSingleDocx: mainDomain + "api/question/upload-single-doc",
    updateFacetoFaceBatchTopic: mainDomain + "api/ftof_batches_days/update",
    addFaceToFaceBatch: mainDomain + "api/ftof_batches/store",
    updateFtoFBatch: mainDomain + "api/ftof_batches/update",
    updateFaceToFaceBatch: mainDomain + "api/ftof_batches_days/update",
    addManualNotification: mainDomain + "api/onesignal-notification",
    AddSubjectFilter: mainDomain + "api/subject-filter/store",
    deleteSubjectFilter: mainDomain + "api/subject-filter/delete/",
    updateCourseCommission: mainDomain + "api/courseCommission/",
    addCourseCommission: mainDomain + "api/courseCommission",
    deleteSubject: mainDomain + "api/subject_master/delete/",
    deleteFaceCat: mainDomain + "api/faceToFaceCategory/",
    deleteFaceSubject: mainDomain + "api/facetofaceSubject/",
    addfacetoface: mainDomain + "api/facetoface",
    createReplica: mainDomain + "api/createReplica",
    deleteQuery: mainDomain + "api/queries/",
    deleteCourse: mainDomain + "api/course/delete/",
    addAmemity: mainDomain + "api/amenities",
    addCourseWings: mainDomain + "api/courseWings",
    UpdateCourse: mainDomain + "api/course/update/",
    addCourse: mainDomain + "api/course/create",
    deleteBatchWing: mainDomain + "api/batchWings/",
    updateBatchWing: mainDomain + "api/batchWings/",
    addBatchWing: mainDomain + "api/batchWings",
    changeSourceStatus: mainDomain + "api/changeSourceStatus/",
    addSource: mainDomain + "api/sources",
    updateSource: mainDomain + "api/sources/",
    addAmenities: mainDomain + "api/allotedAmenities",
    addAllotedAmenity: mainDomain + "api/allotedAmenities",
    addBatches: mainDomain + "api/batches",
    updateBatches: mainDomain + "api/batches/",
    addVersion: "https://core.akclasses.in/" + "api/addVersion",
    deleteMobileSlideImages: mainDomain + "api/mobileSlidingImage/delete/",
    addMobileSlideImages: mainDomain + "api/mobileSlidingImage/create",
    updateMobileSlideImages: mainDomain + "api/mobileSlidingImage/update/",
    changeStatusMobileSlideImages:
      mainDomain + "api/mobileSlidingImage/status/",
    updateCourse: mainDomain + "api/course/update/",
    updateSubjectFilter: mainDomain + "api/subject-filter/update",
    show_questions_for_course: mainDomain + "api/show_questions_for_course",
    show_questions_for_livequiz: mainDomain + "api/show_questions_for_livequiz",
    alreadyAddedQuestions: mainDomain + "api/alreadyAddedQuestions/",
    DeleteSeriesQuestions: mainDomain + "api/delete_series_question/",
    DeleteSubjectQuestions: mainDomain + "api/paperQuestions/",
    AddSeriesQuestions: mainDomain + "api/add_series_questions",
    createCourse: mainDomain + "api/course/create",
    updateStrategy: mainDomain + "api/strategy/",
    createOfflineOrder: mainDomain + "api/offlineOrder/create",
    venderUpdate: mainDomain + "api/vender/update/",
    admin_pass_change: mainDomain + "api/admin_pass_change",
    vendor_offline_add: mainDomain + "api/vendor_offline_add",
    assignCourseStrategy: mainDomain + "api/assignCourseStrategy",
    all_order_report: mainDomain + "api/all_order_report",
    deleteOfflineOrder: mainDomain + "api/offlineOrder/delete/",
    deleteOnlineOrder: mainDomain + "api/online_order/delete/",
    addOnlineShopping: mainDomain + "api/add_onlineshiping/",
    addShippingDetails: mainDomain + "api/online/orderNote/",
    changeOnlineOrderStatus: mainDomain + "api/orderstatus",
    changeTestimonialStatus: mainDomain + "api/testimonial/status/",
    updateTestimonial: mainDomain + "api/testimonial/update/",
    addTestimonial: mainDomain + "api/testimonial/create",
    addUser_strategy: mainDomain + "api/addUser_strategy",
    shipping_detail: mainDomain + "api/shipping_detail/",
    offlineOrderStatus: mainDomain + "api/offlineOrder/order_status/",
    changeVariantStatus: mainDomain + "api/changeVariantStatus/",
    //Question Bank APIs
    createTestSeries: mainDomain + "api/store_test_series",
    addLiveQuizQuestion: mainDomain + "api/addLiveQuizQuestion",
    addSeriesQuestion: mainDomain + "api/add_series_questions",
    addsubjectQuestions: mainDomain + "api/subjectQuestions",
    addPaperQuestions: mainDomain + "api/paperQuestions",
    // scanner level
    createScannerLevel: mainDomain + "api/scanner/levelcreate",
    deleteScannerLevel: mainDomain + "api/scanner/leveldelete/",
    changeScannerLevelStatus: mainDomain + "api/scanner/levelstatus/",
    updateScannerLevel: mainDomain + "api/scanner/levelupdate/",
    upploadScnnareVideos: mainDomain + "api/scanner/videosuploaded/",
    uploadSingleScannerDocx: mainDomain + "api/scanner/upload-single-docc",
    uploadScannerQuestionDocx: mainDomain + "api/scanner/uploaded",

    //scanner subject

    createScannerSubject: mainDomain + "api/scanner/subjectcreate",
    changeScannerSubjectStatus: mainDomain + "api/scanner/subjectstatus/",
    updateScannerSubject: mainDomain + "api/scanner/subjectupdate/",
    deleteScannerSubject: mainDomain + "api/scanner/subjectdelete/",
    //scanner chapter

    changeScannerChapterStatus: mainDomain + "api/scanner/chapterstatus/",
    createScannerChapter: mainDomain + "api/scanner/chaptercreate",
    updateScannerChapter: mainDomain + "api/scanner/chapterupdate/",
    deleteScannerChpater: mainDomain + "api/scanner/chpaterdelete/",
    deleteScannerQuestion: mainDomain + "api/scanner/questiondelete/",
    changeScannerquestionStatus: mainDomain + "api/scanner/questionstatus/",

    //scanner source
    createScannerSource: mainDomain + "api/scanner/sourcecreate",
    deleteScannerSource: mainDomain + "api/scanner/deletesource/",
    changeSourcetStatus: mainDomain + "api/scanner/sourcetstatus/",
    updateScannerSource: mainDomain + "api/scanner/sourceupdate/",
    createQuestionBank: mainDomain + "api/storequestionbank",
    updateQuestionBank: mainDomain + "api/updatequestionbank/",
    deleteQuestionBank: mainDomain + "api/destroyquestionbank/",
    deleteLiveQuizQuestion: mainDomain + "api/destroyquestion/",
    changeQuestionBankStatus: mainDomain + "api/changeQuestionBankStatus/",
    updateType: mainDomain + "api/question/update-type",

    createQuestionBankLevel: mainDomain + "api/storelevel",
    updateQuestionBankLevel: mainDomain + "api/updatelevel/",
    deleteQuestionBankLevel: mainDomain + "api/destroylevel/",
    changeQuestionBankLevelStatus: mainDomain + "api/changeLevelStatus/",

    createQuestionBankSubject: mainDomain + "api/storesubject",
    updateQuestionBankSubject: mainDomain + "api/updatesubject/",
    deleteQuestionBankSubject: mainDomain + "api/destroysubject/",
    changeQuestionBankSubjectStatus: mainDomain + "api/changeSubjectStatus/",

    createQuestionBankChapter: mainDomain + "api/storechapter",
    updateQuestionBankChapter: mainDomain + "api/updatechapter/",
    deleteQuestionBankChapter: mainDomain + "api/destroychapter/",
    changeQuestionBankChapterStatus: mainDomain + "api/changeChapterStatus/",

    createCategory: mainDomain + "api/category/create",
    createSubCategory: mainDomain + "api/subCategory/create",
    createFaculty: mainDomain + "api/faculty/create",
    facultyDelete: mainDomain + "api/faculty/delete",
    createCourseType: mainDomain + "api/courseType/create",
    createComplexityLevel: mainDomain + "api/complexityLevel/create",
    createTag: mainDomain + "api/tag/create",
    createPageContent: mainDomain + "api/pageContent/create",
    createPageLink: mainDomain + "api/pageLink/create",
    createDeliveryMode: mainDomain + "api/deliveryMode/create",
    createProduct: mainDomain + "api/product/create",
    createCourseFeature: mainDomain + "api/courseFeature/create",
    createCourseContent: mainDomain + "api/courseContent/create",
    updateCategory: mainDomain + "api/category/update/",
    updateCourseFeature: mainDomain + "api/courseFeature/update/",
    updateCourseContent: mainDomain + "api/courseContent/update/",
    updateSubCategory: mainDomain + "api/subCategory/update/",
    updateFaculty: mainDomain + "api/faculty/update/",
    updateCourseType: mainDomain + "api/courseType/update/",
    updateComplexityLevel: mainDomain + "api/complexityLevel/update/",
    updateTag: mainDomain + "api/tag/update/",
    updatePageContent: mainDomain + "api/pageContent/update/",
    updatePageLink: mainDomain + "api/pageLink/update/",
    updateDeliveryMode: mainDomain + "api/deliveryMode/update/",
    updateProduct: mainDomain + "api/product/update/",
    categoryStatus: mainDomain + "api/category/status/",
    subCategoryStatus: mainDomain + "api/subCategory/status/",
    facultyStatus: mainDomain + "api/faculty/status/",
    courseTypeStatus: mainDomain + "api/courseType/status/",
    complexityLevelStatus: mainDomain + "api/complexityLevel/status/",
    tagStatus: mainDomain + "api/tag/status/",
    pageContentStatus: mainDomain + "api/pageContent/status/",
    pageLinkStatus: mainDomain + "api/pageLink/status/",
    deliveryModeStatus: mainDomain + "api/deliveryMode/status/",
    productStatus: mainDomain + "api/product/status/",
    courseFeatureStatus: mainDomain + "api/courseFeature/status/",
    courseContentStatus: mainDomain + "api/courseContent/status/",
    updateWhatStudentSay: mainDomain + "api/updateWhatStudentSay/",
    login: mainDomain + "api/login",
    register: mainDomain + "api/register",
    manageNotiForQuiz: mainDomain + "api/StoreLiveQuizNotification",
    createCourse: mainDomain + "api/courseDocuments/create",
    createSubject: mainDomain + "api/subjectDocuments/create",
    createChapter: mainDomain + "api/chapterDocuments/create",
    // Update Api's

    updateAmenity: mainDomain + "api/amenities/",

    // DELETE Api's

    deleteAmenity: mainDomain + "api/amenities/",
    updateCourse: mainDomain + "api/courseDocuments/update",
    updateSubject: mainDomain + "api/subjectDocuments/update",
    updateChapter: mainDomain + "api/chapterDocuments/update",

  
  },
  deleteUrls: {
    deleteBulkQuestionbank: mainDomain + "api/destroybulkquestionbank",
    deletebulkPaperMasterQuestion: mainDomain + "api/destroyBulkPaperQuestion",
    deleteBulkQuestionbankPermanent: mainDomain + "api/permanantMultipleDelete",
    destroyScannerbulk: mainDomain + "api/scanner/destroyscannerbulk",
    permananScannertMultipleDelete:
      mainDomain + "api/scanner/permananscannertmultipledelete",
    deleteDocuments: mainDomain + "api/courseDocuments/delete",
  },
  walletUrl:{
    getAllRecords: mainDomain + "api/wallet/getAllRecords?user_id=${id}&sortBy=created_at",
  },
  TrackerSubject:{
    getAll:mainDomain + "api/subject",
    postSuject: mainDomain + "api/subject",
    
  },
  TrackerChapter:{
    getAll:mainDomain + "api/chapter"

  },
  TrackerSubChapter:{
    getAll:mainDomain + "api/subchapter"

  },
  tracker:{
    fetchTracker:mainDomain + "api/revisiontracker",
    postTracker:mainDomain + "api/revisiontracker",

    
  },
  progress:{
    fetchProgress:mainDomain + "api/users-with-trackers"
  },
  userWithTracker:{
    fetchUserData:mainDomain + "api/user/",
    delete:mainDomain + "api/deleteUser/"
  }

 
  
};
export default Constants;
