import React, { useEffect, useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { Link } from "react-router-dom";

const FinalMaster = () => {
  const [trackData, setTrackData] = useState({});
  const [text, setText] = useState(true);

  const { id } = useParams();

  const textChange = () => {
    setText((prevText) => !prevText);
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const fetchData = async (user_id) => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/user/${user_id}/trackers`
      );
      setTrackData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const style = {
    marginLeft: 60,
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: "50%",
    padding: "10px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
  };
  const goPreviousPage = () => {};

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Link to="/admin/trackerAlottedUser">
                    <ArrowBackOutlinedIcon style={style} />
                  </Link>
                  <strong
                    style={{
                      marginLeft: 90,
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    {" "}
                    Revision Progress
                  </strong>
                </div>
              </Card>
            </GridItem>
          </GridContainer>

          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Typography
                  variant="caption"
                  component="div"
                  color="textPriary"
                >
                  <p style={{marginLeft:200}}>Student Name:- <strong>{trackData.user_name}</strong></p>
                </Typography>
              </GridItem>
            </GridContainer>
            <div style={{ padding: 16, borderRadius: 8, width: 700 }}>
              {trackData.trackers?.map((tracker, trackerIndex) => (
                <div key={tracker.tracker_id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ marginTop: 10 }}>
                      <strong style={{ marginLeft: 26 }}>
                        {tracker.tracker_title}
                      </strong>
                      <p style={{ marginTop: 20, marginLeft: 26 }}>
                        Overall Progress:
                      </p>
                    </div>
                    <h2
                      style={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        height: "45px",
                        width: 100,
                        textAlign: "center",
                        color: "blue",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 53,
                      }}
                    >
                      {tracker.tracker_percent}%
                    </h2>
                  </div>

                  <ExpansionPanelDetails onClick={textChange}>
                    {tracker.subjects?.map((subject) => (
                      <ExpansionPanel key={subject.subject_id}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon style={{ opacity: 0 }} />}
                          aria-controls={`subject-panel-${subject.subject_id}-content`}
                          id={`subject-panel-${subject.subject_id}-header`}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                          >
                            {/* Left Section: Circular Progress and Title */}
                            <Box
                              display="flex"
                              alignItems="center"
                              flexGrow={1}
                            >
                              {/* Circular Progress */}
                              <Box
                                position="relative"
                                display="inline-flex"
                                mr={2}
                              >
                                <svg
                                  viewBox="0 0 36 36"
                                  style={{
                                    transform: "rotate(-90deg)",
                                    width: 70,
                                    height: 70,
                                  }}
                                >
                                  {/* Background Circle */}
                                  <circle
                                    cx="18"
                                    cy="18"
                                    r="15.91549430918954"
                                    fill="none"
                                    stroke="#e0e0e0"
                                    strokeWidth="4"
                                  />
                                  {/* Progress Circle */}
                                  <circle
                                    cx="18"
                                    cy="18"
                                    r="15.91549430918954"
                                    fill="none"
                                    stroke="#3f51b5"
                                    strokeWidth="4"
                                    strokeDasharray={`${subject.subject_percent}, 100`}
                                    strokeLinecap="round"
                                  />
                                </svg>
                                {/* Percentage Text */}
                                <Box
                                  top={0}
                                  left={0}
                                  bottom={0}
                                  right={0}
                                  position="absolute"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    {`${subject.subject_percent}%`}
                                  </Typography>
                                </Box>
                              </Box>

                              {/* Subject Title */}
                              <Typography
                                variant="h6"
                                style={{
                                  marginBottom: 5,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                }}
                                title={subject.subject_title}
                              >
                                {subject.subject_title}
                              </Typography>
                            </Box>

                            {/* Right Section: Check Progress Button */}
                            <Box display="flex" alignItems="center">
                              <p
                                // onClick={textChange}
                                style={{
                                  paddingLeft: 5,
                                  backgroundColor: "#3f51b5",
                                  color: "#fff",
                                  cursor: "pointer",
                                  marginLeft: 60,
                                  display: "inline-flex",
                                  borderRadius: 4,
                                }}
                              >
                                {text ? "Check Progress" : "Hide Progress"}
                                <ExpandMoreIcon />
                              </p>
                            </Box>
                          </Box>
                        </ExpansionPanelSummary>

                        {subject.chapters.map((chapter) => (
                          <ExpansionPanel key={chapter.chapter_id}>
                            <ExpansionPanelSummary
                              expandIcon={
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  border="2px solid blue"
                                  borderRadius="50%"
                                  width={30}
                                  height={30}
                                >
                                  <ExpandMoreIcon style={{ color: "blue" }} />
                                </Box>
                              }
                              aria-controls={`chapter-panel-${chapter.chapter_id}-content`}
                              id={`chapter-panel-${chapter.chapter_id}-header`}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                flexGrow={1}
                              >
                                {/* Chapter Title */}
                                <Typography variant="subtitle1">
                                  {chapter.chapter_title}
                                </Typography>

                                {/* Chapter Percentage */}
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  mt={1}
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    value={chapter.chapter_percent}
                                    style={{ width: "100%", marginRight: 8 }}
                                  />
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    {chapter.chapter_percent}%
                                  </Typography>
                                </Box>
                              </Box>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails>
                              <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                              >
                                {chapter.sub_chapters.map((subChapter) => (
                                  <Box
                                    key={subChapter.sub_chapter_id}
                                    width="100%"
                                    mb={2}
                                  >
                                    {/* Sub Chapter Row */}
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      width="100%"
                                      mb={1}
                                    >
                                      {/* Sub Chapter Title */}
                                      <Typography
                                        variant="body2"
                                        style={{ flexGrow: 1 }}
                                      >
                                        {subChapter.sub_chapter_title}
                                      </Typography>

                                      {/* Checkbox */}
                                      <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            subChapter.is_completed === 1
                                          }
                                          readOnly
                                        />
                                      </Box>
                                    </Box>

                                    {/* Horizontal Line */}
                                    <hr
                                      style={{
                                        border: "0.5px solid #ccc",
                                        margin: 0,
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                      </ExpansionPanel>
                    ))}
                  </ExpansionPanelDetails>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default FinalMaster;
