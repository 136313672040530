import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import axios from "axios";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
//  import Grid from "@mui/material/Grid";
//  import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import { DateRangePicker } from "materialui-daterange-picker";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Title,
  Tooltip,
  Legend,
  Animation,
} from "devextreme-react/chart";

const Graph = () => {
  const [onlineCourseData, setOnlineCourseData] = useState([]);
  const [offlineCourseData, setOfflineCourseData] = useState([]);
  const [offlineOrderType, setOfflineOrderType] = useState("Course");
  const [onlineOrderType, setOnlineOrderType] = useState("Course");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openOffline, setOpenOffline] = useState(false);
  const [openOnline, setOpenOnline] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const toggleOffline = () => setOpenOffline((prevOpen) => !prevOpen);
  const toggleOnline = () => setOpenOnline((prevOpen) => !prevOpen);

  useEffect(() => {
    offlineCourse();
    onlineCourse();
  }, []);

  const offlineCourse = async () => {
    const today = new Date();
    const endDate = today.toISOString().split("T")[0];

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const startDate = threeMonthsAgo.toISOString().split("T")[0];

    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/offlineOrdersFilter?ORDER_TYPE=Course&start_date=${startDate}&end_date=${endDate}`
      );
      const sortedData = res.data.ordersByDate.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setOfflineCourseData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  const onlineCourse = async () => {
    const today = new Date();
    const endDate = today.toISOString().split("T")[0];

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const startDate = threeMonthsAgo.toISOString().split("T")[0];

    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/onlineOrderDateTypeFilter?ORDER_TYPE=COURSE&start_date=${startDate}&end_date=${endDate}`
      );
      const sortedData = res.data.ordersByDate.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setOnlineCourseData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOfflineChange = (e) => {
    setOfflineOrderType(e.target.value);
    const today = new Date();
    const endDatee = today.toISOString().split("T")[0];

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const startDatee = threeMonthsAgo.toISOString().split("T")[0];

    let url = "";
    if (e.target.value === "Course") {
      url = `https://admin.akclasses.in/api/offlineOrdersFilter?ORDER_TYPE=Course&start_date=${startDatee}&end_date=${endDatee}`;
    } else {
      url = `https://admin.akclasses.in/api/offlineOrdersFilter?ORDER_TYPE=BOOKS`;
    }
    axios
      .get(url)
      .then((res) => {
        const sortedData = res.data.ordersByDate.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        setOfflineCourseData(sortedData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  const offlineSearchWithDate = async () => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/offlineOrdersFilter?ORDER_TYPE=${offlineOrderType}&start_date=${startDate}&end_date=${endDate}`
      );
      const sortedData = res.data.ordersByDate.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setOfflineCourseData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };
  const onlineSearchWithDate = async () => {
    try {
      const res = await axios.get(
        `https://admin.akclasses.in/api/onlineOrderDateTypeFilter?ORDER_TYPE=${onlineOrderType}&start_date=${startDate}&end_date=${endDate}`
      );
      const sortedData = res.data.ordersByDate.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      setOnlineCourseData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnlineChange = (e) => {
    setOnlineOrderType(e.target.value);
    const today = new Date();
    const endDatee = today.toISOString().split("T")[0];

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const startDatee = threeMonthsAgo.toISOString().split("T")[0];

    let url = "";
    if (e.target.value === "Course") {
      url = `https://admin.akclasses.in/api/onlineOrderDateTypeFilter?ORDER_TYPE=Course&start_date=${startDatee}&end_date=${endDatee}`;
    } else {
      url = `https://admin.akclasses.in/api/onlineOrderDateTypeFilter?ORDER_TYPE=BOOKS&start_date=${startDatee}&end_date=${endDatee}`;
    }
    axios
      .get(url)
      .then((res) => {
        const sortedData = res.data.ordersByDate.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        setOnlineCourseData(sortedData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  const handleDateRangeChangeOffline = (range) => {
    setDateRange(range);
    setStartDate(range.startDate.toISOString().split("T")[0]);
    setEndDate(range.endDate.toISOString().split("T")[0]);
    setOpenOffline(false);
  };

  const handleDateRangeChangeOnline = (range) => {
    setDateRange(range);
    setStartDate(range.startDate.toISOString().split("T")[0]);
    setEndDate(range.endDate.toISOString().split("T")[0]);
    setOpenOnline(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper
            className="paper"
            elevation={6}
            style={{
              padding: "20px",
              borderRadius: "16px",
              backgroundColor: "#ffffff",
            }}
          >
            {/* Offline Course Bar Graph */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
              }}
            >
              <FormControl variant="outlined" style={{ minWidth: 140 }}>
                <InputLabel
                  id="order-type-label"
                  style={{ fontWeight: "bold" }}
                >
                  Order Type
                </InputLabel>
                <Select
                  labelId="offline-order-type-label"
                  id="orderTypeOffline"
                  value={offlineOrderType}
                  onChange={handleOfflineChange}
                  label="Order Type"
                  style={{ borderRadius: "8px", backgroundColor: "#f0f0f0" }}
                >
                  <MenuItem value="Course">Course</MenuItem>
                  <MenuItem value="BOOKS">Book</MenuItem>
                </Select>
              </FormControl>

              <Button
                color="primary"
                onClick={toggleOffline}
                variant="contained"
                style={{
                  borderRadius: "30px",
                  padding: "12px 25px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #6e7f99, #3a519d)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Select Date Range
              </Button>
              <Button
                color="success"
                onClick={offlineSearchWithDate}
                variant="contained"
                style={{
                  borderRadius: "30px",
                  padding: "12px 25px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #6e7f99, #3a519d)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Search
              </Button>
            </div>

            {/* <div style={{ marginTop: "20px" }}>
        <h3 style={{ color: "#3a519d", fontWeight: "bold" }}>Selected Date Range:</h3>
        <p style={{ fontSize: "16px", fontStyle: "italic" }}>
          {dateRange ? `${dateRange.startDate} - ${dateRange.endDate}` : "None"}
        </p>
      </div> */}

            {openOffline && (
              <DateRangePicker
                open={openOffline}
                toggle={toggleOffline}
                onChange={handleDateRangeChangeOffline}
              />
            )}

            <Chart dataSource={offlineCourseData}>
              <ArgumentAxis title="Date" label={{ format: "shortDate" }} />
              <ValueAxis>
                <Title text="Order Count" />
              </ValueAxis>

              <Series
                valueField="orderCount"
                argumentField="date"
                name="Offline Course"
                type="bar"
                color="#3a519d"
                hoverStyle={{ border: { color: "white", width: 2 } }}
                barWidth={20}
                style={{ borderRadius: "6px" }}
              />

              <Legend verticalAlignment="top" horizontalAlignment="center" />
              <Tooltip
                enabled={true}
                customizeTooltip={(arg) => ({
                  text: `Date: ${arg.argumentText}\nOrder Count: ${arg.valueText}`,
                })}
              />
              <Animation enabled={true} />
            </Chart>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            className="paper"
            elevation={6}
            style={{
              padding: "20px",
              borderRadius: "16px",
              backgroundColor: "#ffffff",
              // backgroundColor: "#f5f5f5",
            }}
          >
            {/* Online Course Bar Graph */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
              }}
            >
              <FormControl variant="outlined" style={{ minWidth: 140 }}>
                <InputLabel
                  id="order-type-label"
                  style={{ fontWeight: "bold" }}
                >
                  Order Type
                </InputLabel>
                <Select
                  labelId="online-order-type-label"
                  id="orderTypeOnline"
                  value={onlineOrderType}
                  onChange={handleOnlineChange}
                  label="Order Type"
                  style={{ borderRadius: "8px", backgroundColor: "#f0f0f0" }}
                >
                  <MenuItem value="Course">Course</MenuItem>
                  <MenuItem value="BOOKS">Book</MenuItem>
                </Select>
              </FormControl>

              <Button
                color="primary"
                onClick={toggleOnline}
                variant="contained"
                style={{
                  borderRadius: "30px",
                  padding: "12px 25px",
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #6e7f99, #26c6da)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Select Date Range
              </Button>
              <Button
                color="info"
                onClick={onlineSearchWithDate}
                variant="contained"
                style={{
                  borderRadius: "30px",
                  padding: "12px 25px",
                  fontWeight: "bold",
                  background: "#26c6da",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Search
              </Button>
            </div>

            {/* <div style={{ marginTop: "20px" }}>
        <h3 style={{ color: "#26c6da", fontWeight: "bold" }}>Selected Date Range:</h3>
        <p style={{ fontSize: "16px", fontStyle: "italic" }}>
          {dateRange ? `${dateRange.startDate} - ${dateRange.endDate}` : "None"}
        </p>
      </div> */}

            {openOnline && (
              <DateRangePicker
                open={openOnline}
                toggle={toggleOnline}
                onChange={handleDateRangeChangeOnline}
              />
            )}

            <Chart dataSource={onlineCourseData}>
              <ArgumentAxis title="Date" label={{ format: "shortDate" }} />
              <ValueAxis>
                <Title text="Order Count" />
              </ValueAxis>

              <Series
                valueField="orderCount"
                argumentField="date"
                name="Online Course"
                type="bar"
                color="#26c6da"
                hoverStyle={{ border: { color: "white", width: 2 } }}
                barWidth={20}
                style={{ borderRadius: "6px" }}
              />

              <Legend verticalAlignment="top" horizontalAlignment="center" />
              <Tooltip
                enabled={true}
                customizeTooltip={(arg) => ({
                  text: `Date: ${arg.argumentText}\nOrder Count: ${arg.valueText}`,
                })}
              />
              <Animation enabled={true} />
            </Chart>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Graph;
