import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import FolderSpecialRoundedIcon from "@material-ui/icons/FolderSpecialRounded";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LinkIcon from "@material-ui/icons/Link";

const API_URL = "https://admin.akclasses.in/api/directories";

const YouTubeMaterial = () => {
  const [folders, setFolders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [title, setTitle] = useState("");
  const [dirType, setDirType] = useState("");
  const [link, setLink] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [icon, setIcon] = useState("");
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderStack, setFolderStack] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [language,setLanguage] = useState("")
  const [descripTion,setDesCripTion] = useState('')

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    try {
      const response = await axios.get(API_URL);
      setFolders(response.data.result);
    } catch (error) {
      console.error("Error fetching folders", error);
    }
  };

  // const handleUpdateFolder = async () => {
  //   const formData = new FormData();
  //   formData.append("title", title);
  //   formData.append("dir_type", dirType);
  //   formData.append("thumbnail", thumbnail);
  //   formData.append("icon", icon);
  //   formData.append("link", link);

  //   try {
  //     await axios.put(`${API_URL}/${selectedFolder.id}`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //     setSuccessMessage("Folder updated successfully!");
  //     setTimeout(() => {
  //       closeDialog();
  //       fetchFolders();
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error updating folder", error);
  //   }
  // };

  const openFolder = (folder) => {
    setFolderStack((prevStack) => [...prevStack, currentFolder]);
    setCurrentFolder(folder);
  };

  const goBackToFolder = (index) => {
    const updatedStack = folderStack.slice(0, index);
    setCurrentFolder(folderStack[index] || null);
    setFolderStack(updatedStack);
  };

  const handleCreateFolderIconClick = (folderId) => {
    setParentId(folderId);
    setOpenDialog(true);
  };

  const handleCloudUploadIconClick = (folderId) => {
    setParentId(folderId);
    setOpenUploadDialog(true);
  };

  const handleCreateParentFolderClick = () => {
    setParentId(0);
    setOpenDialog(true);
  };

  const handleRightClick = (event, folder) => {
    event.preventDefault();
    setSelectedFolder(folder);
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const handleUpdateClick = async () => {
    setTitle(selectedFolder.title);
    setDirType(selectedFolder.dir_type);
    setThumbnail(selectedFolder.thumbnail);
    setIcon(selectedFolder.icon);
    setLink(selectedFolder.link);
    setOpenDialog(true);
    closeContextMenu();
  };

  const handleDeleteClick = () => {
    setOpenConfirmDialog(true);
    closeContextMenu();
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/${selectedFolder.id}`);
      setSuccessMessage("file deleted successfully!");
      setTimeout(() => {
        closeConfirmDialog();
      }, 2000);
      window.location.reload();
      await fetchFolders();
    } catch (error) {
      console.error("Error deleting folder", error);
    }
  };

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedFolder(null);
  };

  // const confirmDelete = async () => {
  //   try {
  //     await axios.delete(`${API_URL}/${selectedFolder.id}`);
  //     closeConfirmDialog();
  //     // Fetch folders again to refresh the list
  //     await fetchFolders();
  //   } catch (error) {
  //     console.error("Error deleting folder", error);
  //   }
  // };

  const createFolder = async () => {
    const formData = new FormData();
    if (!selectedFolder) {
      formData.append("parent_id", parentId || 0);
    }
    formData.append("title", title);
    formData.append("dir_type", dirType);
    formData.append("thumbnail", thumbnail);
    formData.append("icon", icon);
    formData.append("link", link);
    formData.append("status","active");
    formData.append("language",language);
    formData.append("description",descripTion);

    if (!title.trim()) return;

    try {
      if (selectedFolder) {
        await axios.post(`${API_URL}/${selectedFolder.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setSuccessMessage(" folder update successfully");
        setTimeout(() => {
          closeDialog();
        }, 3000);
        fetchFolders();
      } else {
        await axios.post(`${API_URL}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      setSuccessMessage(" folder add successfully");
      setTimeout(() => {
        closeDialog();
      }, 3000);
      window.location.reload()
      await fetchFolders();
    
    } catch (error) {
      console.error("Error creating/updating folder", error);
    }
  };

  // const createFolder = async () => {
  //   const formData = new FormData();

  //   if (!selectedFolder) {
  //     formData.append("parent_id", parentId || 0);
  //   }

  //   if (dirType === 'dir') {
  //     // Only append title, icon, and thumbnail if dir_type is 'dir'
  //     formData.append("title", title);
  //     formData.append("icon", icon);
  //     formData.append("thumbnail", thumbnail);
  //   } else {
  //     // Append all fields for other dir_type values
  //     formData.append("title", title);
  //     formData.append("dir_type", dirType);
  //     formData.append("thumbnail", thumbnail);
  //     formData.append("icon", icon);
  //     formData.append("link", link);
  //   }

  //   if (!title.trim()) return;

  //   try {
  //     const url = selectedFolder ? `${API_URL}/${selectedFolder.id}` : `${API_URL}`;
  //     await axios.post(url, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     fetchFolders();
  //     closeDialog();
  //   } catch (error) {
  //     console.error("Error creating/updating folder", error);
  //   }
  // };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("parent_id", parentId || 0);
    formData.append("title", title);
    formData.append("dir_type", "video");
    formData.append("link", link);
    formData.append("status","active");
    formData.append("language",language);
    formData.append("description",descripTion);

    if (!link.trim()) return;

    try {
      await axios.post(`${API_URL}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSuccessMessage("Link Uploaded Successfully");
      setTimeout(() => {
        closeUploadDialog();
      }, 3000);
      window.location.reload()
      await fetchFolders();
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setTitle("");
    setThumbnail("");
    setDirType("");
    setIcon("");
    setLink("");
    setSelectedFolder(null);
  };

  const closeUploadDialog = () => {
    setOpenUploadDialog(false);
    setTitle("");
    setLink("");
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) return;

    const updatedFolders = Array.from(folders);
    const [movedFolder] = updatedFolders.splice(source.index, 1);
    updatedFolders.splice(destination.index, 0, movedFolder);

    setFolders(updatedFolders);

    // Send the updated order to the server
    try {
      await axios.put(`${API_URL}/update-order`, {
        folders: updatedFolders.map((folder) => folder.id),
      });
    } catch (error) {
      console.error("Error updating folder order", error);
    }
  };
  
  const renderFolders = (folders, parentFolderId) => {
    if (!folders || folders.length === 0) {
      return (
        <div style={{ textAlign: "center", padding: "20px", color: "gray" }}>
          <p>Nothing is available in this folder.</p>
          <p>Current Folder ID: {currentFolder ? currentFolder.id : "Root"}</p>
          <div>
            {/* Display the Create Folder and Upload icons here */}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleCreateFolderIconClick(currentFolder.id); 
              }}
            >
              <Tooltip title="Create Folder">
                <CreateNewFolderIcon style={{ color: "green" }} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleCloudUploadIconClick(currentFolder.id);
              }}
            >
              <Tooltip title="Upload Link">
                <CloudUploadIcon style={{ color: "red" }} />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      );
    }
  
    return folders.map((folder, index) => (
      <Draggable
        key={folder.id}
        draggableId={folder.id.toString()}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              marginBottom: 10,
              ...provided.draggableProps.style,
            }}
          >
            <div
              style={{
                padding: "10px",
                border: "1px solid #d1d1d1",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => openFolder(folder)}
              onContextMenu={(event) => handleRightClick(event, folder)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {folder.dir_type === "video" ? (
                  <LinkIcon
                    style={{
                      color: "blue",
                      width: "fit-content",
                      height: 100,
                      pointerEvents: "none", 
                    }}
                  />
                ) : (
                  <Badge
                    badgeContent={folder.children?.length || 0}
                    color="primary"
                  >
                    <FolderSpecialRoundedIcon
                      style={{ color: "skyblue", width: 70, height: 70 }}
                    />
                  </Badge>
                )}
                <span style={{ marginLeft: 10 }}>{folder.title}</span>
              </div>
              <div>
                {folder.dir_type !== "video" && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCreateFolderIconClick(folder.id);
                      }}
                    >
                      <Tooltip title="Create Inside Folder">
                        <CreateNewFolderIcon style={{ color: "green" }} />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloudUploadIconClick(folder.id);
                      }}
                    >
                      <Tooltip title="Upload Link">
                        <CloudUploadIcon style={{ color: "red" }} />
                      </Tooltip>
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            {/* Show table if folder type is video */}
            {folder.dir_type === "video" && folder.link && (
              <div style={{ maxWidth: "600px", marginBottom: "20px" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#f9f9f9",
                        borderBottom: "2px solid #ddd",
                      }}
                    >
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "left",
                          width: "50%",
                        }}
                      >
                        Link Title
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "left",
                          width: "50%",
                        }}
                      >
                        Link URL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        transition: "background-color 0.2s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f1f1f1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "#fff")
                      }
                    >
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        {folder.title}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                        <a
                          href={folder.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "blue", textDecoration: "underline" }}
                        >
                          {folder.link}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Draggable>
    ));
  };
  
  
  

  const foldersToShow = currentFolder ? currentFolder.children : folders;

  const renderBreadcrumb = () => {
    if (!folderStack.length && !currentFolder) return null;
    return (
      <div style={{ marginBottom: 10 }}>
        <strong>Path: </strong>
        {folderStack.map((folder, index) => (
          <span key={folder?.id || index}>
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => goBackToFolder(index)}
            >
              {folder?.title}
            </span>{" "}
            /{" "}
          </span>
        ))}
        {currentFolder && <span>{currentFolder.title}</span>}
      </div>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <Tooltip title="Create Folder">
          <CreateNewFolderIcon
            style={{ width: 70, height: 70, color: "green" }}
            onClick={handleCreateParentFolderClick}
          />
        </Tooltip>
      </div>

      {currentFolder && (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => goBackToFolder(folderStack.length - 1)}
        >
          Back
        </Button>
      )}

      {renderBreadcrumb()}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                border: "1px solid #ccc",
                padding: 10,
                borderRadius: "8px",
                backgroundColor: "#fff",
                maxHeight: "600px",
                overflowY: "auto",
              }}
            >
              {renderFolders(foldersToShow)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Context Menu */}
      <Menu
        open={contextMenu !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleUpdateClick}>Update</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>

      {/* Dialog components for create folder, upload file, and delete confirmation */}
      <Dialog open={openDialog} onClose={closeDialog}>
        <DialogTitle>
          {selectedFolder ? "Update Folder" : "Create Folder"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Title"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Language"
            fullWidth
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
           <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={descripTion}
            onChange={(e) => setDesCripTion(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Directory Type</InputLabel>
            <Select
              value={dirType}
              onChange={(e) => setDirType(e.target.value)}
              label="Directory Type"
            >
              <MenuItem value="dir">Folder</MenuItem>
              <MenuItem value="video">Video</MenuItem>
            </Select>
          </FormControl>
          {dirType === "dir" && (
           <>
           <TextField
             margin="dense"
             label="Thumbnail"
             type="file"
             fullWidth
             InputLabelProps={{ shrink: true }}
             onChange={(e) => setThumbnail(e.target.files[0])} 
           />
           <TextField
             margin="dense"
             label="Icon"
             type="file"
             fullWidth
             InputLabelProps={{ shrink: true }}
             onChange={(e) => setIcon(e.target.files[0])}
           />
         </>
         
          )}
          {dirType === "video" && (
            <TextField
              margin="dense"
              label="Link"
              fullWidth
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          )}
          {successMessage && (
            <div style={{ color: "green", marginTop: "10px" }}>
              {successMessage}
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={createFolder} color="primary">
            {selectedFolder ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUploadDialog} onClose={closeUploadDialog}>
        <DialogTitle>Upload Link</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Link"
            type="text"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Language"
            type="text"
            fullWidth
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={descripTion}
            onChange={(e) => setDesCripTion(e.target.value)}
          />
          {successMessage && (
            <div style={{ color: "green", marginTop: "10px" }}>
              {successMessage}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFileUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this folder?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await confirmDelete();
              closeConfirmDialog();
            }}
            color="primary"
          >
            Delete
          </Button>
          {successMessage && (
            <div style={{ color: "green", marginTop: "10px" }}>
              {successMessage}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default YouTubeMaterial;
